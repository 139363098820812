import React, { useEffect, useState } from "react";
import Card from "../../../components/Card";
import { useDispatch, useSelector } from "react-redux";
import { activityMasterDetails } from "./activityMasterService";
import {
  setActivityEditable,
  setMasterActivities,
} from "./activityMasterSlice";
import Table from "../../../components/Table/Table";
import { EditLogo } from "../../../assets/images/svg";
import { useNavigate } from "react-router-dom";

const List = () => {
  const [callApi, setCallApi] = useState(true);

  const dispatch = useDispatch();

  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchActivityMaster = async () => {
      const data = await activityMasterDetails(token);
      dispatch(setMasterActivities(data));
    };

    fetchActivityMaster();
    setCallApi(true);
  }, [dispatch, token, callApi]);

  const onAddclick = () => {
    console.log("on add clickeddd");
    dispatch(setActivityEditable(true));
    navigate("/activity-master/add");
  };

  const activities = useSelector((state) => state.activityMaster.activities);
  const isEditable = useSelector(
    (state) => state.activityMaster.isActivityEditable
  );
  console.log(isEditable);

  const activityStatusList = ["ALL", "Active", "Inactive"];

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <span
          className="text-truncate"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title={row.name}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`${row.id}`);
          }}
        >
          {row.name}
        </span>
      ),
    },
    {
      name: "Curriculum",
      selector: (row) => row.curriculum_name,
      sortable: true,
    },
    {
      name: "Grade",
      selector: (row) => row.grade_name,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: false,
      cell: (row) => (
        <span className={`status ${row.status.toLowerCase()}`}>
          {row.status}
        </span>
      ),
    },
    {
      name: "",
      button: true,
      cell: (row) => (
        <button
          className="btn"
          onClick={() => {
            dispatch(setActivityEditable(true));
            navigate(`${row.id}`);
          }}
        >
          <EditLogo />
        </button>
      ),
    },
  ];

  return (
    <div className="activity__master--page">
      <Card>
        <div className="activity__master--list p-3 pt-2">
          <Table
            entity="activity-master"
            data={activities}
            columns={columns}
            statusList={activityStatusList}
            onAddClick={onAddclick}
          />
        </div>
      </Card>
    </div>
  );
};

export default List;
