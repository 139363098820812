import React, { useState } from "react";
import "./addActivity.scss";
import ActivityDetail from "./detail/ActivityDetail";
import AddHomeExtension from "./homeExtensions/AddHomeExtension";
const AddActivity = () => {
  const [activeTab, setActiveTab] = useState("details");

  return (
    <div className="add__activity--page">
      <div className="tabs page-tab">
        <div
          className={`tab ${activeTab === "details" ? "tab-active" : ""}`}
          onClick={() => setActiveTab("details")}
        >
          Details
        </div>
        <div
          className={`tab ${
            activeTab === "homeExtensions" ? "tab-active" : ""
          }`}
          onClick={() => setActiveTab("homeExtensions")}
        >
          Home Extensions
        </div>
      </div>
      {activeTab === "details" ? <ActivityDetail /> : <AddHomeExtension />}
    </div>
  );
};

export default AddActivity;
