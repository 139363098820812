import axios from "axios";
import { DEV_BASE_URL } from "../../../utils/constants/routes";

export const activtyMediaDetails = async (token, id) => {
    console.log(id)
    const response = await axios.get(`${DEV_BASE_URL}/api/v1/dashboard/class_media/${id}`, {
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    })

    return response.data.data
}


export const deleteMediaService = async(token, deleteMedias) => {
    console.log(token, deleteMedias)
    const response = await axios.post(`${DEV_BASE_URL}/api/v1/activity/delete`, 
        {
            "media_ids" : deleteMedias
        },
        {
            headers : {
                'Authorization' : `Bearer ${token}`
            }
        }
    )
    return response.data
}

export const publishMediaService = async(token, publishMedias) => {
    console.log(token, publishMedias)
    const response = await axios.post(`${DEV_BASE_URL}/api/v1/dashboard/add_to_highlights`,
        {
            "media_ids" : publishMedias
        },
        {
            headers : {
                'Authorization' : `Bearer ${token}`
            }
        }
    )
    return response.data
}

export const getClassStatus = async(token, id) => {
    console.log(token, id)
    console.log(id)
    const response = await axios.get(`${DEV_BASE_URL}/class/${id}`, 
        {
            headers : {
                'Authorization' : `Bearer ${token}`
            }
        }
    )
    return response.data.data.status
}

export const updateClassStatusService = async(token, id, status) => {
    const response = await axios.post(`${DEV_BASE_URL}/api/v1/dashboard/update_class/${id}`, 
        {
            status
        },
        {
            headers : {
                'Authorization' : `Bearer ${token}`
            }
        }
    )
    return response.data

}

export const autoPost = async(token, id, template) => {
    const response = await axios.post(`${DEV_BASE_URL}/api/v1/dashboard/autoPost`,
        {
            "template" : template,
            "from" : "",
            "activity_id" : id
        },
        {
            headers : {
                'Authorization' : `Bearer ${token}`
            }
        }
    )
    return response.data
}