import { useState } from "react";
import "./formInput.scss";
import Select from "react-select";

const FormInput = (props) => {
  const [focused, setFocused] = useState(false);
  const { label, errorMessage, onChange, id, className, type, ...inputProps } =
    props;

  const handleFocus = (e) => {
    setFocused(true);
  };

  return (
    <div
      className={` formInput ${className} position-relative form-group mb-2`}
    >
      <label
        htmlFor={id}
        className={type === "checkbox" ? "checkbox--label" : ""}
      >
        {label}
      </label>
      {type === "select" ? (
        inputProps.multiple === true ? (
          <Select
            isMulti
            name={inputProps.name}
            defaultValue={inputProps.defaultValue}
            placeholder={inputProps.placeholder}
            options={inputProps.options}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={onChange}
            isDisabled={inputProps.disabled}
          />
        ) : (
          <select
            {...inputProps}
            onChange={onChange}
            onBlur={handleFocus}
            focused={focused.toString()}
            className="form-select"
            multiple={inputProps.multiple}
          >
            <option value="" disabled selected>
              Select {inputProps.placeholder ?? "Option"}
            </option>
            {inputProps.options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        )
      ) : type === "radio" ? (
        <div className="radioOption d-flex gap-3">
          {inputProps.options.map((option) => (
            <div key={option.value} className="radioOptionItem">
              <input
                {...inputProps}
                type="radio"
                id={`${id}-${option.value}`}
                name={inputProps.name}
                value={option.value}
                onChange={onChange}
                onBlur={handleFocus}
                focused={focused.toString()}
                className="form-check-input"
                // checked={inputProps.value === option.value}
              />

              <label htmlFor={`${id}-${option.value}`}>{option.label}</label>
            </div>
          ))}
        </div>
      ) : type === "textarea" ? (
        <textarea
          {...inputProps}
          onChange={onChange}
          onBlur={handleFocus}
          focused={focused.toString()}
          className="form-control"
        />
      ) : type === "file" ? (
        <input
          type="file"
          className={`form-control`}
          id={inputProps.id}
          name={inputProps.name}
          onChange={onChange}
          disabled={inputProps.disabled}
        />
      ) : (
        <input
          id={id}
          {...inputProps}
          type={type}
          onChange={onChange}
          onBlur={handleFocus}
          onFocus={() =>
            inputProps.name === "confirmPassword" && setFocused(true)
          }
          checked={type === "checkbox" ? inputProps.checked : undefined}
          value={type !== "checkbox" ? inputProps.value : undefined}
          focused={focused.toString()}
          className={
            type === "checkbox"
              ? "position-absolute checkbox--box form-check-input "
              : "form-control"
          }
        />
      )}
      {type !== "checkbox" && type !== "radio" && <span>{errorMessage}</span>}
    </div>
  );
};

export default FormInput;
