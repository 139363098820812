import axios from "axios";
import { DEV_BASE_URL } from "../../../utils/constants/routes";

export const assessmentDetails = async (token, id) => {
  console.log(id);
  const response = await axios.post(
    `${DEV_BASE_URL}/assessment/details`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        course_member_id: id,
      },
    }
  );

  console.log(response.data.data);

  return response.data.data;
};

//V2 assessment details supporting multiple uploads
export const assessmentDetailsV2 = async (token, course_member_id) => {
  try {
    const response = await axios.get(
      `${DEV_BASE_URL}/assessment/multiple/details`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          course_member_id: course_member_id,
        },
      }
    );
    console.log(response.data);

    return response.data;
  } catch (error) {
    console.log(error);
    return {
      status: false,
      message: `Failed to fetch data from server ${error.message}`,
    };
  }
};

export const assessmentStatusUpdate = async (
  token,
  id,
  status,
  user_id,
  message
) => {
  console.log(id, status, user_id);
  const response = await axios.post(
    `${DEV_BASE_URL}/assessment/status/update`,
    {
      remark: message,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        course_assessment_submission_id: id,
        status: status,
        course_member_id: user_id,
      },
    }
  );

  console.log(response.data);
  return response.data;
};

export const assessmentFileStatusUpdate = async (
  submission_details_id,
  status,
  remark
) => {
  try {
    const response = await axios.post(
      `${DEV_BASE_URL}/assessment/details/status/update`,
      { remark },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: {
          submission_details_id: submission_details_id,
          status: status,
        },
      }
    );

    if (response.data.status) {
      return response.data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.log(error);
    return {
      status: false,
      message: `Failed to fetch data from server ${error.message}`,
    };
  }
};

export const vivaSchedule = async (
  token,
  id,
  startDate,
  endDate,
  mode,
  link
) => {
  console.log(token, id, startDate, endDate, mode, link);

  const start = new Date(startDate);
  const end = new Date(endDate);

  console.log(start, end);

  const differenceInMilliseconds = end - start;
  console.log(differenceInMilliseconds);

  const differenceInMinutes = differenceInMilliseconds / (1000 * 60); // Convert ms to minutes
  console.log(differenceInMinutes);

  let duration = `${differenceInMinutes} mins`;

  if (differenceInMinutes >= 60) {
    const hours = Math.floor(differenceInMinutes / 60); // Correctly calculate hours
    const minutes = differenceInMinutes % 60; // Remaining minutes after subtracting hours

    console.log("Difference is greater than or equal to 60 minutes");

    if (minutes === 0) {
      duration = `${hours} hrs`;
    } else {
      duration = `${hours} hrs ${minutes} mins`;
    }
  }

  const response = await axios.post(
    `${DEV_BASE_URL}/assessment/viva/schedule?course_assessment_submission_id=${id}`,
    {
      url: link,
      duration: duration,
      start_time: startDate,
      end_time: endDate,
      comment: "please join on time",
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        course_assessment_submission_id: id,
      },
    }
  );

  return response.data;
};
