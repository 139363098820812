import React from "react";
import "./index.scss";

const Button = ({
  label,
  onClick,
  backgroundColor,
  textColor,
  className = "",
  type = "button",
  disabled = false,
}) => {
  const buttonStyle = {
    backgroundColor,
    color: textColor,
  };

  return (
    <button
      className={`btn button ${className ? className : ""}`}
      style={buttonStyle}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default Button;
