import React, { useState } from "react";
import { CrossLogo } from "../../../assets/images/svg";
import Button from "../../../components/Button";
import { addCurriculum } from "./curricumListService";
import DynamicFormRenderer from "../../../components/Form/DynamicFormRenderer";

const AddCurriculumModal = ({
  setShowModal,
  showAlert,
  setShowAlert,
  alertMessage,
  setAlertMesage,
  setApiCall,
}) => {
  const statusOptions = ["active", "inactive"];
  const [currentCurriculum, setCurrentCurriculum] = useState({});

  const formConfig = [
    {
      label: "Id",
      name: "id",
      type: "text",
      placeholder: "Enter Id",
      disabled: true,
    },
    {
      label: "Name",
      name: "name",
      type: "text",
      placeholder: "Enter Name",
      required: true,
    },
    {
      label: "Status",
      name: "status",
      type: "select",
      options: [
        {
          label: "Active",
          value: "active",
        },
        {
          label: "Inactive",
          value: "inactive",
        },
      ],
      required: true,
    },
    {
      label: "Description",
      name: "description",
      type: "text",
      placeholder: "Enter Description",
      required: true,
    },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentCurriculum((prev) => ({
      ...prev,
      [name]: value, // Dynamically update the correct field
    }));
  };

  const handleFormSubmit = async (data) => {
    Object.keys(data).forEach((key) => {
      if (data[key] === "") {
        delete data[key];
      }
    });
    const response = await addCurriculum(data);
    setShowAlert(true);
    setAlertMesage("Added Entry in Curriculum");
    setShowModal(false);
    setApiCall(true);
  };

  return (
    <div>
      <div
        className="modal show fade d-block"
        role="dialog"
        style={{ backgroundColor: "rgba(0,0,0,.5)" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Curriculum</h5>
              <div className="close-modal" onClick={() => setShowModal(false)}>
                <CrossLogo />
              </div>
            </div>
            <div className="modal-body reject-modal-body">
              {/* <div className="add-curriculum-form">
            <div className="row mb-2 p-2">
                <div className="col-6">
                <label htmlFor="curriculumId">Id</label>
                <input
                    type="text"
                    className="form-control"
                    id="curriculumId"
                    name="id" // Add name for controlled component
                    placeholder="Enter Id"
                    onChange={handleInputChange}
                readOnly/>
                </div>
                <div className="col-6">
                <label htmlFor="curriculumName">Name</label>
                <input
                    type="text"
                    className="form-control"
                    id="curriculumName"
                    name="name" // Add name for controlled component
                    placeholder="Enter Name"
                    onChange={handleInputChange}
                />
                </div>
            </div>
            <div className="row mb-2 p-2">
                <div className="col-6">
                <label htmlFor="curriculumStatus">Status</label>
                <select
                    className="form-control"
                    id="curriculumStatus"
                    name="status" // Add name for controlled component
                    onChange={handleInputChange}
                >
                    <option value="">Select Status</option> 
                    {statusOptions.map((status) => (
                    <option key={status} value={status}>
                        {status}
                    </option>
                    ))}
                </select>
                </div>
                <div className="col-6">
                <label htmlFor="curriculumDescription">Description</label>
                <textarea
                    type="text" // Changed type to "text" for description
                    className="form-control"
                    id="curriculumDescription"
                    name="description" // Add name for controlled component
                    placeholder="Enter Description"
                    rows="3"
                    onChange={handleInputChange}
                ></textarea>
                </div>
            </div>
        </div>
              </div>
              <div className="modal-footer">
                 <Button label="Save" onClick={handleFormSubmit}  backgroundColor="#EA983E" textColor="white"/>
              </div> */}
              <DynamicFormRenderer
                formConfig={formConfig}
                hasCancelBtn={false}
                handleSubmit={handleFormSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCurriculumModal;
