import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activities: [],
  isActivityEditable : false
};

const activityMasterSlice = createSlice({
  name: "activityMaster",
  initialState,
  reducers: {
    setMasterActivities: (state, action) => {
      state.activities = action.payload;
    },
    setActivityEditable : (state, action) => {
      state.isActivityEditable = action.payload
    }
  },
});

export const { setMasterActivities, setActivityEditable } = activityMasterSlice.actions;

export default activityMasterSlice.reducer;
