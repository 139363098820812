import axios from "axios";
import { DEV_BASE_URL } from "../../../utils/constants/routes";

export const getSchoolDropdown = async (token) => {
  const response = await axios.get(
    `${DEV_BASE_URL}/addActivity/dropdown/school`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  if (response.data.status) {
    let result = response.data.data;
    let data = [];
    result.forEach((item) => {
      data.push({
        value: item.id,
        label: item.name,
      });
    });
    return data;
  } else {
    return [];
  }
};

export const getActivitiesDropdown = async (token, school_id) => {
  const response = await axios.get(
    `${DEV_BASE_URL}/addActivity/dropdown/activity`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: {
        school_id: school_id,
      },
    }
  );

  if (response.data.status) {
    let result = response.data.data;
    let data = [];
    result.forEach((item) => {
      data.push({
        value: item.id,
        label: item.name,
      });
    });
    return data;
  } else {
    return [];
  }
};

export const getGradeDropdown = async (token, school_id) => {
  const response = await axios.get(
    `${DEV_BASE_URL}/addActivity/dropdown/grade`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        school_id: school_id,
      },
    }
  );

  if (response.data.status) {
    let result = response.data.data;
    let data = [];
    result.forEach((item) => {
      data.push({
        value: item.id,
        label: item.name,
      });
    });
    return data;
  } else {
    return [];
  }
};

export const getGradeSectionDropDown = async (token, school_id, grade_id) => {
  const response = await axios.get(
    `${DEV_BASE_URL}/addActivity/dropdown/section`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        school_id: school_id,
        grade_id: grade_id,
      },
    }
  );

  if (response.data.status) {
    let result = response.data.data;
    let data = [];
    result.forEach((item) => {
      data.push({
        value: item.id,
        label: item.name,
      });
    });
    return data;
  } else {
    return [];
  }
};

export const getSchoolStaffDropdown = async (token, school_id) => {
  const response = await axios.get(
    `${DEV_BASE_URL}/addActivity/dropdown/staff`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        school_id: school_id,
      },
    }
  );

  if (response.data.status) {
    let result = response.data.data;
    let data = [];
    result.forEach((item) => {
      data.push({
        value: item.id,
        label: item.name,
      });
    });
    return data;
  } else {
    return [];
  }
};

export const addActivity = async (
  token,
  school_id,
  school_activity_id,
  grade_id,
  section_id,
  staff_ids,
  start_time,
  end_time
) => {
  console.log(
    token,
    school_id,
    school_activity_id,
    grade_id,
    section_id,
    staff_ids
  );
  const response = await axios.post(
    `${DEV_BASE_URL}/addActivity/activity`,
    {
      school_id,
      school_activity_id,
      grade_id,
      section_id,
      staff_ids,
      start_time,
      end_time,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.data.status) {
    let result = response.data;
    return result;
  } else {
    return [];
  }
};

export const updateActivity = async (
  start_date,
  end_date,
  status,
  activity_id
) => {
  const response = await axios.post(
    `${DEV_BASE_URL}/api/v1/updateActivitySession`,
    {
      start_date,
      end_date,
      status,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: {
        activity_id: activity_id,
      },
    }
  );

  if (response.data.status) {
    let result = response.data;
    return result;
  } else {
    return [];
  }
};
