import { AudioWave, PauseLogo, PlayButton } from "../../../../assets/images/svg";
import "./audioPlayer.scss"
import { useState, useRef, useEffect } from "react";

const SpotlightVivaItem = ({ media_url, onClick, filename, currentAudio, setCurrentAudio }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);



  useEffect(() => {
    const audio = audioRef.current;

    if (audio) {
      // Update isPlaying state when audio is played/paused
      const handlePlay = () => setIsPlaying(true);
      const handlePause = () => setIsPlaying(false);

      audio.addEventListener("play", handlePlay);
      audio.addEventListener("pause", handlePause);

      // Cleanup event listeners on component unmount
      return () => {
        audio.removeEventListener("play", handlePlay);
        audio.removeEventListener("pause", handlePause);
      };
    }
  }, []);


  const handlePlayPause = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    if (audioRef.current) {
      // If there is a different audio currently playing, pause it
      if (currentAudio && currentAudio !== audioRef.current) {
        currentAudio.pause();
        currentAudio.currentTime = 0
      }

      // Toggle play/pause for the clicked audio
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
        setCurrentAudio(audioRef.current)
      }

      // setIsPlaying(!isPlaying);

      // // Update the currently playing audio reference
      // if (!isPlaying) {
      //   setCurrentAudio(audioRef.current); // Set the current audio to the clicked one
      // } else {
      //   setCurrentAudio(null); // If paused, clear the current audio
      // }
    }
  };

  const getTruncatedFilename = (filename) => {
    const maxLength = 29;
    if (filename.length > maxLength) {
      return filename.substring(0, maxLength) + '...';
    }
    return filename;
  };

  return (
    <div className="spotlight--item--container">
      <div className="spotlight--item--author" onClick={onClick}>
        <div className="spotlight--item--content">
            <div>{getTruncatedFilename(filename)}</div>
          <div className="spotlight--item--audio">
            <div className="play--button" onClick={handlePlayPause}>
              {!isPlaying ? <PlayButton /> : <PauseLogo />}
            </div>
            <div className="audio--wave">
              <AudioWave />
            </div>
            <audio ref={audioRef} src={media_url}></audio>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpotlightVivaItem;
