import axios from "axios";
import { DEV_BASE_URL } from "../../../utils/constants/routes";

export const curriculumDetails = async() => {
    const response = await axios.get(
        `${DEV_BASE_URL}/api/v1/getCurriculum`,
        {
            headers: {
                'Authorization' : `Bearer ${localStorage.getItem('token')}`
            }
        }
    )

    return response.data.data
}

export const addCurriculum = async(curriculumData) => {
    const response = await axios.post(
        `${DEV_BASE_URL}/api/v1/createCurriculum`,
        curriculumData,
        {
            headers: {
                'Authorization' : `Bearer ${localStorage.getItem('token')}`
            }
        }
    )

    return response.data.data
}