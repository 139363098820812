import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import Button from "../../../components/Button";
import { uploadActivityMedia, uploadThumbnail } from "./addActivityMediaService";
import { generateThumbnailVideo } from "../../../utils/thumbnailGenerator";
import "./addActivityMedia.scss";
import { useParams } from "react-router-dom";
import heic2any from "heic2any";

function AddActivityMedia({ currentTab, uploadActivityStatus, setUploadActivityStatus }) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [overallProgress, setOverallProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false); // New state to manage upload status
  const { id } = useParams();

  const onDrop = useCallback((acceptedFiles) => {
    setOverallProgress(0)
    const newFiles = acceptedFiles.map(file => file);
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleReset = () => {
    setSelectedFiles([]);
    setOverallProgress(0);
  };

  const uploadImages = async () => {
    setIsUploading(true); // Disable buttons while uploading
    const totalFiles = selectedFiles.length;
    let completedUploads = 0;

    for (const file of selectedFiles) {
      let media_category = '';
      if (currentTab === 'logbook') media_category = 'logsheet';
      else if (file.type.startsWith('image/')) media_category = 'photo';
      else if (file.type.startsWith('video/')) media_category = 'video';
      else media_category = 'viva';

      let fileToUpload = file;
      if (file.name.endsWith('.heic')) {
        const blob = await heic2any({
          blob: file,
          toType: 'image/jpeg',
          quality: 1,
        });
        fileToUpload = new File([blob], `${file.name.replace(/\.heic$/, '.jpg')}`, {
          type: 'image/jpeg',
        });
      }

      const data = await uploadActivityMedia(fileToUpload, id, media_category, 1);
      setUploadActivityStatus((prev) => prev+1);

      completedUploads++;
      setOverallProgress(Math.round((completedUploads / totalFiles) * 100));

      let thumbnail;
      if (file.type.startsWith('video/')) {
        thumbnail = await generateThumbnailVideo(fileToUpload);
      }

      if (thumbnail) {
        await uploadThumbnail(thumbnail, data.data.class_media_id);
      }
    }

    setSelectedFiles([]);
    setOverallProgress(0);
    setIsUploading(false); // Re-enable buttons after upload is complete
  };

  return (
    <div className="upload-media-container">
      <div {...getRootProps()}>
        <div className="upload-media">
          <input {...getInputProps()} />
          
            <p className="drap-drop-box">
              <br/>
              Drag 'n' drop some files here, or click to select files
            </p>
        </div>
      </div>
      <div className="content-wrapper">
        {selectedFiles.length > 0 ? (
          <div className="selected-media-container">
            {selectedFiles.map((file) => (
              <div key={file.name}>
                {file.type.startsWith("image/") ? (
                  <img
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                    width="100"
                    height="100"
                  />
                ) : (
                  <p>{file.name}</p>
                )}
              </div>
            ))}
          </div>
        ) : (
          <p>No files selected yet.</p>
        )}
        
        {selectedFiles.length > 0 && <div className="progress-bar">
          <div
            className="progress"
            style={{ width: `${overallProgress}%` }}
          />
        </div>}
        <span>{overallProgress}%</span>
        

        <div className="button-wrapper">
          <Button
            label="Upload"
            onClick={uploadImages}
            disabled={isUploading||selectedFiles.length==0} // Disable during upload
          />
          <Button
            label="Reset"
            onClick={handleReset}
            disabled={isUploading||selectedFiles.length==0} // Disable during upload
          />
        </div>
      </div>
    </div>
  );
}

export default AddActivityMedia;
