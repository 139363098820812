import { useDispatch, useSelector } from "react-redux";
import Table from "../../../../components/Table/Table";
import {
  BanLogo,
  CheckLogo,
  CloseLogo,
  CrossLogo,
  DownloadLogo,
  EyeLogo,
} from "../../../../assets/images/svg";
import { closeModal, openModal } from "../../../../components/Modal/ModalSlice";
import Modal from "../../../../components/Modal/Modal";
import Button from "../../../../components/Button";
import FormInput from "../../../../components/Form/FormInput";
import { useEffect, useState } from "react";
import { setSelectedAssessment } from "../courseDetailsSlice";
import {
  assessmentFileStatusUpdate,
  assessmentStatusUpdate,
} from "../courseDetailsService";
import { useNavigate, useParams } from "react-router-dom";
import { capitalizeString } from "../../../../utils/capitalizer";
import AlertWithIcon from "../../../../components/Alerts/AlertWithIcon";

const SYLListing = () => {
  const dispatch = useDispatch();

  const syl_evidences = useSelector(
    (state) =>
      state.assessmentDetail.assessmentDetailsMultipleFileTypes[
        "Show Your Learning"
      ]
  );

  console.log("syl_evidences", syl_evidences);

  const columns = [
    {
      id: "id",
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      width: "80px",
    },
    {
      id: "Type",
      name: "Type",
      selector: (row) => (row.file_type === null ? "Video" : row.file_type),
      sortable: true,
      width: "120px",
    },
    {
      id: "title",
      name: "Description / Title",
      selector: (row) => row.title,
      cell: (row) => (
        <span
          className="text-truncate"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title={row.title ?? "Not Specified"}
        >
          {row.title ?? "Not Specified"}
        </span>
      ),
      sortable: true,
      width: "230px",
    },
    {
      id: "upload_date",
      name: "Uploaded On",
      selector: (row) => row.created_on,
      sortable: true,
      width: "150px",
    },
    {
      id: "approve_date",
      name: "Approved On",
      selector: (row) => row.modified_on,
      sortable: true,
      width: "150px",
    },
    {
      id: "approval_status",
      name: "Approval Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <span className={`status ${row.status && row.status.toLowerCase()}`}>
          {row.status ?? "UNKNOWN"}
        </span>
      ),
      width: "140px",
    },
    {
      id: "action",
      name: "Action",
      cell: (row) => (
        <div className="d-flex justify-content-start">
          <button
            className="btn"
            onClick={() => {
              dispatch(setSelectedAssessment(row));
              dispatch(openModal("evidence_modal"));
            }}
          >
            <EyeLogo />
          </button>
          <button
            className="btn"
            onClick={() => {
              // Download the file.
              const link = document.createElement("a");
              link.href = row.url;
              link.download = row.url.split("/").pop();
              link.click();
            }}
          >
            <DownloadLogo />
          </button>
        </div>
      ),
      width: "120px",
    },
    {
      id: "approve",
      name: "Approve ?",
      cell: (row) => (
        <div className={`d-flex justify-content-start`}>
          {row.status && row.status.toUpperCase() === "SUBMITTED" ? (
            <>
              <button
                className="btn"
                onClick={() => {
                  dispatch(setSelectedAssessment(row));
                  dispatch(openModal("approval_modal"));
                }}
              >
                <CheckLogo />
              </button>

              <button
                className="btn"
                onClick={() => {
                  dispatch(setSelectedAssessment(row));
                  dispatch(openModal("rejection_modal"));
                }}
              >
                <CloseLogo />
              </button>
            </>
          ) : (
            <button className="btn">
              <BanLogo />
            </button>
          )}
        </div>
      ),
      width: "120px",
    },
  ];

  return (
    <section className="syl__listing">
      <div className="title p-3 border-black border-bottom fw-bold">
        SYL Evidences
      </div>
      <Table
        entity={"syl_evidences"}
        columns={columns}
        data={syl_evidences ?? []}
        clickableRow={false}
        hasAddBtn={false}
        statusList={["ALL", "APPROVED", "REJECTED", "SUBMITTED"]}
        hasActionButtons={
          syl_evidences && syl_evidences.length
            ? syl_evidences[0].section_status === "submitted"
            : false
        }
        actionButtons={{
          positiveActionLabel: "Approve Stage",
          negativeActionLabel: "Reject Stage",
          section_completed:
            syl_evidences && syl_evidences.length
              ? syl_evidences[0].section_status === "completed"
              : false,
          completedMessage: "Approved",
          section_rejected:
            syl_evidences && syl_evidences.length
              ? syl_evidences[0].section_status === "rejected"
              : false,
          rejectedMessage: "Rejected",
          negativeAction: () => {
            dispatch(setSelectedAssessment(syl_evidences[0]));
            dispatch(openModal("stage_rejection_modal"));
          },
          positiveAction: () => {
            dispatch(setSelectedAssessment(syl_evidences[0]));
            dispatch(openModal("stage_approval_modal"));
          },
        }}
      />
      <SYLListingModal />
    </section>
  );
};

export default SYLListing;

const SYLListingModal = () => {
  const dispatch = useDispatch();
  const modalType = useSelector((state) => state.modal.modalType);
  // console.log(modalType);

  const { id } = useParams();

  const handleEvidenceApproveReject = async (
    remark,
    selectedEvidence,
    status
  ) => {
    // api call to approve this evidence
    try {
      const data = await assessmentFileStatusUpdate(
        selectedEvidence.id,
        // "approved",
        status,
        remark
      );
      if (data.status) {
        alert(`File ${capitalizeString(status)}`);
      } else {
        throw new Error(data.message);
      }
      console.log(`${status} response`, data);
    } catch (error) {
      alert(error.message);
    } finally {
      dispatch(closeModal());
      //refresh page
      window.location.reload();
    }
  };

  const handleStageApproveReject = async (
    remark,
    selectedAssessment,
    status
  ) => {
    // api call to approve this stage
    try {
      const response = await assessmentStatusUpdate(
        localStorage.getItem("token"),
        selectedAssessment.course_assessment_submission_id,
        status,
        id,
        remark
      );
      if (response.status) {
        alert(`Stage ${capitalizeString(status)}`);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      alert(error.message);
    } finally {
      dispatch(closeModal());
      //refresh page
      // window.location.reload();
    }
  };

  switch (modalType) {
    case "stage_approval_modal":
      return (
        <ApprovalRejectionModalContent
          title="Approve Stage"
          btnLabel="Approve"
          onSubmit={handleStageApproveReject}
          status="approve"
        />
      );
    case "stage_rejection_modal":
      return (
        <ApprovalRejectionModalContent
          title="Reject Stage"
          btnLabel="Reject"
          onSubmit={handleStageApproveReject}
          status="reject"
        />
      );
    case "approval_modal":
      return (
        <ApprovalRejectionModalContent
          title="Approve Evidence"
          btnLabel="Approve"
          onSubmit={handleEvidenceApproveReject}
          status={"approved"}
        />
      );
    case "rejection_modal":
      return (
        <ApprovalRejectionModalContent
          title="Reject Evidence"
          btnLabel="Reject"
          onSubmit={handleEvidenceApproveReject}
          status={"rejected"}
        />
      );
    case "evidence_modal":
      return <EvidenceContentModal />;
    default:
      return null;
  }
};

const ApprovalRejectionModalContent = ({
  title,
  btnLabel,
  onSubmit,
  status,
}) => {
  const dispatch = useDispatch();
  const selectedEvidence = useSelector(
    (state) => state.assessmentDetail.selectedAssessment ?? {}
  );
  const modalType = useSelector((state) => state.modal.modalType);
  const toShowRemarkField =
    modalType !== "stage_approval_modal" &&
    modalType !== "stage_rejection_modal";
  const [remark, setRemark] = useState("");

  return (
    <Modal title={title}>
      <div className="d-flex h-100 flex-column">
        <h6>Are you sure you want to {title}? </h6>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit && onSubmit(remark, selectedEvidence, status);
          }}
        >
          {toShowRemarkField && (
            <FormInput
              name="comment"
              label="Comments"
              type="textarea"
              placeholder="Any comments/remarks..."
              rows={5}
              value={remark}
              className="my-3"
              onChange={(e) => setRemark(e.target.value)}
            />
          )}
          <div className="d-flex justify-content-end gap-3 mt-3 mb-3">
            <Button
              label={btnLabel}
              backgroundColor={"#f6921e"}
              textColor={"#fff"}
              type="submit"
            />
            <Button
              label={"Cancel"}
              onClick={() => {
                dispatch(closeModal());
              }}
              backgroundColor={"#fff"}
              textColor={"#000"}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

const EvidenceContentModal = ({}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  const evidence = useSelector(
    (state) => state.assessmentDetail.selectedAssessment ?? {}
  );

  useEffect(() => {
    setIsLoading(true);
  }, [evidence.url]);

  // Utility function to determine the file type
  const getFileType = (url) => {
    if (!url) return "unknown";

    // Extract the file extension even if URL has query parameters
    const urlWithoutQuery = url.split("?")[0];
    const extension = urlWithoutQuery.split(".").pop().toLowerCase();
    if (["mp4", "webm", "ogg"].includes(extension)) return "video";
    if (["mp3", "wav", "ogg", "m4a"].includes(extension)) return "audio";
    if (["jpg", "jpeg", "png", "gif", "bmp"].includes(extension))
      return "image";
    if (extension === "pdf") return "pdf";
    if (["ppt", "pptx"].includes(extension)) return "ppt";
    return "unknown";
  };

  // Determine the file type from the URL
  const fileType = evidence?.url ? getFileType(evidence.url) : "unknown";

  const ErrorAndLoader = () => (
    <>
      {isLoading && (
        <div
          className="spinner-border text-primary position-absolute top-50 start-50"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      )}
      {isError && (
        <p className="align-self-center text-white">Error loading File</p>
      )}
    </>
  );

  // Render content based on the file type
  const renderContent = () => {
    switch (fileType) {
      case "audio": // New audio case
        return (
          <audio
            src={evidence.url}
            controls
            style={{ width: "100%" }}
            className="align-self-center"
            onCanPlayThrough={() => setIsLoading(false)} // When audio is ready to play
            onError={() => {
              setIsLoading(false);
              console.error("Error loading audio");
            }}
          >
            Your browser does not support the audio element.
          </audio>
        );
      case "video":
        return (
          <video
            src={evidence.url}
            controls
            style={{ width: "fit-content", height: "100%" }}
            className="align-self-center"
          />
        );
      case "image":
        return (
          <img
            src={evidence.url}
            alt="Evidence"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        );
      case "pdf":
        return (
          <div style={{ width: "100%", height: "100%", position: "relative" }}>
            <iframe
              src={`https://docs.google.com/gview?url=${encodeURIComponent(
                evidence.url
              )}&embedded=true`}
              title="Evidence PDF"
              style={{ width: "100%", height: "100%", border: "none" }}
              onLoad={() => setIsLoading(false)}
              onError={() => {
                setIsLoading(false);
                setIsError(true);
              }}
            />
            <ErrorAndLoader />
          </div>
        );
      case "ppt":
        return (
          <div style={{ width: "100%", height: "100%", position: "relative" }}>
            <iframe
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                evidence.url
              )}`}
              title="Evidence PowerPoint"
              style={{ width: "100%", height: "80vh" }}
              onLoad={() => setIsLoading(false)}
              onError={() => {
                setIsLoading(false);
                setIsError(true);
              }}
            />
            <ErrorAndLoader />
          </div>
        );
      default:
        return (
          <p className="align-self-center text-white">
            Unsupported file format. Please ensure the file type is one of the
            following:
            <br />
            - Video (mp4, webm, ogg)
            <br />
            - Audio (mp3, wav, ogg, m4a)
            <br />
            - Image (jpg, jpeg, png, gif, bmp)
            <br />
            - PDF
            <br />- PowerPoint (ppt, pptx)
          </p>
        );
    }
  };

  return (
    <Modal
      title="Evidence"
      onCloseClick={() => {
        dispatch(setSelectedAssessment(null));
        dispatch(closeModal());
      }}
    >
      <div className="row p-2" style={{ height: "85vh" }}>
        <div
          className="col-md-7 d-flex justify-content-center align-items-center bg-black rounded"
          style={{ height: "100%", padding: "10px" }}
        >
          {renderContent()}
        </div>

        <div className="col-md-5">
          <AlertWithIcon
            type="primary"
            message="Consider downloading if it keeps loading..."
            icon="info"
          />
          {evidence.comment && (
            <div className="d-flex flex-column h-100">
              <p className="fs-4 border-bottom border-dark">Remark</p>
              <div className="flex-grow-1 overflow-auto ">
                <div className="card">
                  <div className="card-body">{evidence.comment}</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
