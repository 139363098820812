import axios from "axios";
import { DEV_BASE_URL } from "../../../utils/constants/routes";

export const getCourseMaster = async (course_id = "") => {
  try {
    const response = await axios.get(
      `${DEV_BASE_URL}/api/v1/courses/getCourse`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: {
          course_id,
        },
      }
    );

    if (response.status) {
      return response.data.data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.log(error);
  }
};

export const addToCourseMaster = async (data) => {
  try {
    const response = await axios.post(
      `${DEV_BASE_URL}/api/v1/courses/addCourse`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (response.status) {
      return response.data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateCourseMasterCourse = async (data) => {
  try {
    const response = await axios.post(
      `${DEV_BASE_URL}/api/v1/courses/updateCourse`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (response.status) {
      return response.data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.log(error);
  }
};

/**************************** ASSESSMENTS ******************************/

export const getCourseAssessment = async (
  course_id = "",
  course_assessment_id = ""
) => {
  try {
    const response = await axios.get(
      `${DEV_BASE_URL}/api/v1/courses/getAssessment`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: {
          course_id,
          course_assessment_id,
        },
      }
    );

    if (response.status) {
      return response.data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.log(error);
  }
};

export const addCourseAssessment = async (data) => {
  try {
    const response = await axios.post(
      `${DEV_BASE_URL}/api/v1/courses/addAssessment`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (response.status) {
      return response.data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateCourseAssessment = async (data) => {
  try {
    const response = await axios.post(
      `${DEV_BASE_URL}/api/v1/courses/updateAssessment`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (response.status) {
      return response.data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.log(error);
  }
};

/************************************** COURSE DROPDOWN ******************************************/
export const getCircleCourseDropdown = async () => {
  try {
    const response = await axios.get(`${DEV_BASE_URL}/courses?dropdown=true`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status) {
      return response.data.data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.log(error);
    return [];
  }
};
