import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    curriculums : [],
    isEditable : false
};

const curriculumSlice = createSlice({
    name : 'curriculums',
    initialState,
    reducers : {
        setCurriculum : (state, action) => {
            state.curriculums = action.payload
        },
        setEditMode : (state, action) => {
            state.isEditable = action.payload
        }
    }
})

export const {setCurriculum, setEditMode} = curriculumSlice.actions;

export default curriculumSlice.reducer