// components/CustomAlert.js
import React from 'react';
import './alert.scss';

const Alert = ({ message, type = 'info', onClose }) => {
  return (
    <div className={`custom-alert ${type}`}>
      <span className="custom-alert-message">{message}</span>
      <button className="custom-alert-close" onClick={onClose}>
        &times;
      </button>
    </div>
  );
};

export default Alert;
