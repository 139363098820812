import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchSingleCurriculumData,
  updateCurriculum,
} from "../curriculumDetailsService";
import "./detail.scss";
import Button from "../../../../../components/Button";
import Alert from "../../../../../components/Alerts/Alert";
import { setEditMode } from "../../curriculumSlice";
import FormInput from "../../../../../components/Form/FormInput";

const CurriculumDetailsTab = ({ editable }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const isEdit = useSelector((state) => state.curriculumList.isEditable);
  console.log(isEdit);
  const [currentCurriculum, setCurrentCurriculum] = useState({}); // Initialize with an empty object
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ];
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCurriculum = async () => {
      const response = await fetchSingleCurriculumData(id);
      setCurrentCurriculum(response);
    };

    fetchCurriculum();

    return () => {
      dispatch(setEditMode(false));
    };
  }, []);

  const updateCurrentCurriculum = async () => {
    const data = await updateCurriculum(currentCurriculum);
    setAlertMessage("Updated Curriculum");
    setShowAlert(true);
    // navigate("/curriculum-master");
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentCurriculum((prev) => ({
      ...prev,
      [name]: value, // Dynamically update the correct field
    }));
  };

  const editCurriculumFormConfig = [
    {
      id: "curriculumId",
      label: "Id",
      name: "id",
      type: "text",
      placeholder: "Enter Id",
      value: currentCurriculum?.id || "",
      readOnly: true,
      disabled: true,
    },
    {
      id: "curriculumName",
      label: "Name",
      name: "name",
      type: "text",
      placeholder: "Enter Name",
      value: currentCurriculum?.name || "",
      onChange: isEdit && handleInputChange,
      readOnly: !isEdit,
      disabled: !isEdit,
      required: true,
      errorMessage: "Curriculum name is required",
    },
    {
      id: "curriculumStatus",
      label: "Status",
      name: "status",
      type: "select",
      placeholder: "Status",
      options: statusOptions,
      value: currentCurriculum?.status || "",
      onChange: isEdit && handleInputChange,
      readOnly: !isEdit,
      disabled: !isEdit,
      required: true,
    },
    {
      id: "curriculumDescription",
      label: "Description",
      name: "description",
      type: "text",
      placeholder: "Enter Description",
      value: currentCurriculum?.description || "",
      onChange: isEdit && handleInputChange,
      readOnly: !isEdit,
      disabled: !isEdit,
    },
  ];

  console.log(currentCurriculum);

  return (
    <div className="curriculum-details">
      {showAlert && (
        <Alert
          message={alertMessage}
          type="success"
          onClose={() => setShowAlert(false)}
        />
      )}
      <div className="add-curriculum-form">
        {/* <div className="row mb-2 p-2"> */}
        {/* <div className="col-6">
            <label htmlFor="curriculumId">Id</label>
            <input
              type="text"
              className="form-control"
              id="curriculumId"
              name="id" // Add name for controlled component
              placeholder="Enter Id"
              value={currentCurriculum?.id || ""} // Provide a fallback to avoid uncontrolled component warning
              onChange={false ? handleInputChange : undefined} // Only allow changes if isEdit is true
              readOnly={true}
            />
          </div> */}
        {/* <div className="col-6">
            <label htmlFor="curriculumName">Name</label>
            <input
              type="text"
              className="form-control"
              id="curriculumName"
              name="name" // Add name for controlled component
              placeholder="Enter Name"
              value={currentCurriculum?.name || ""} // Provide a fallback
              onChange={isEdit ? handleInputChange : undefined} // Only allow changes if isEdit is true
              readOnly={!isEdit}
            />
          </div> */}
        {/* </div> */}
        {/* <div className="row mb-2 p-2"> */}
        {/* <div className="col-6">
            <label htmlFor="curriculumStatus">Status</label>
            <select
              className="form-control"
              id="curriculumStatus"
              name="status" // Add name for controlled component
              value={currentCurriculum?.status || ""} // Provide a fallback
              onChange={isEdit ? handleInputChange : undefined} // Only allow changes if isEdit is true
              disabled={!isEdit} // Disable if not editable
            >
              <option value="">Select Status</option>
              {statusOptions.map((status) => (
                <option key={status} value={status}>
                  {status}
                </option>
              ))}
            </select>
          </div> */}
        {/* <div className="col-6">
            <label htmlFor="curriculumDescription">Description</label>
            <input
              type="text" // Changed type to "text" for description
              className="form-control"
              id="curriculumDescription"
              name="description" // Add name for controlled component
              placeholder="Enter Description"
              value={currentCurriculum?.description || ""} // Provide a fallback
              onChange={isEdit ? handleInputChange : undefined} // Only allow changes if isEdit is true
              readOnly={!isEdit}
            />
          </div> */}
        {/* </div> */}

        <form onSubmit={updateCurrentCurriculum}>
          <div className="row px-2">
            {editCurriculumFormConfig.map((input, index) => (
              <FormInput key={index} {...input} className="col-md-6" />
            ))}
          </div>
          <div className="activity-save-btn">
            {isEdit && (
              <Button
                label="Save"
                backgroundColor="#f6921e"
                textColor="white"
                type="submit"
              />
            )}
          </div>
        </form>
      </div>

      {/* <div className="activity-save-btn">
        {isEdit && (
          <Button
            label="Save"
            onClick={updateCurrentCurriculum}
            backgroundColor="#EA983E"
            textColor="white"
          />
        )}
      </div> */}
    </div>
  );
};

export default CurriculumDetailsTab;
