// src/features/login/index.jsx
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setProfile } from './loginSlice';
import { loginApi, fetchUserProfile } from './loginService';
import './index.scss';
import { activity } from '../../utils/constants/routes';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const loginData = await loginApi({ email, password });
      if (loginData.status) {
        const token = loginData.data.token;
        localStorage.setItem('token', token);

        const profileData = await fetchUserProfile(token);
        if (profileData.status) {
          dispatch(setProfile(profileData.data));
          navigate(activity)
        } else {
          //TODO: REPLACE IT WITH TOSTER
          console.error(profileData.message);
        }
      } else {
        console.error(loginData.message);
      }
    } catch (error) {
      console.error(error.toString());
    }
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleSubmit}>
        <h1>Login</h1>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className='submit-button'>
          <button type="submit">Login</button>
        </div>
      </form>
    </div>
  );
};

export default Login;
