import React, { useState } from "react";
import "./addSchool.scss";
import { capitalizeString } from "../../../../utils/capitalizer";
import {
  SchoolActivity,
  SchoolDetail,
  SchoolGrade,
  SchoolSection,
} from "../..";
const AddSchool = () => {
  const [activeTab, setActiveTab] = useState("details");

  const tabList = ["details", "grade", "section", "activity"];

  const renderTab = (tabName, index) => {
    return (
      <div
        key={index}
        className={`tab ${activeTab === tabName ? "tab-active" : ""}`}
        onClick={() => setActiveTab(tabName)}
      >
        {capitalizeString(tabName)}
      </div>
    );
  };

  const renderTabContent = (tabName) => {
    switch (tabName) {
      case "details":
        return <SchoolDetail />;
      case "grade":
        return <SchoolGrade />;
      case "section":
        return <SchoolSection />;
      case "activity":
        return <SchoolActivity />;
      default:
        return <SchoolDetail />;
    }
  };

  return (
    <div className="add__school--page">
      <div className="tabs page-tab">
        {tabList.map((tab, index) => renderTab(tab, index))}
      </div>

      {renderTabContent(activeTab)}
    </div>
  );
};

export default AddSchool;
