import axios from "axios";
import { DEV_BASE_URL } from "../../../../utils/constants/routes";

export const fetchSingleCurriculumData = async(id) => {
    const response = await axios.get(
        `${DEV_BASE_URL}/api/v1/getCurriculum`,
        {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem('token')}`
            },
            params : {
                'curriculum_id' : id
            }
        }
    )
    return response.data.data[0]
}

export const fetchAllGrade = async(id) => {
    const response = await axios.get(
        `${DEV_BASE_URL}/api/v1/getGrade`,
        {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem('token')}`
            },
            params : {
                'curriculum_id' : id
            }
        }
    )
    return response.data.data
}

export const fetchSingleGrade = async(id) => {
    const response = await axios.get(
        `${DEV_BASE_URL}/api/v1/getGrade`,
        {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem('token')}`
            },
            params : {
                'grade_id' : id
            }
        }
    )
    return response.data.data[0]
}

export const updateGrade = async(gradeData) => {
    const response = await axios.post(
        `${DEV_BASE_URL}/api/v1/updateGrade`,
        gradeData,
        {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem('token')}`
            }
        }
    )
    return response.data
}

export const addGrade = async(gradeData) => {
    const response = await axios.post(
        `${DEV_BASE_URL}/api/v1/createGrade`,
        gradeData,
        {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem('token')}`
            }
        }
    )
    return response.data.data
}

export const updateCurriculum = async(curriculumData) => {
    const response = await axios.post(
        `${DEV_BASE_URL}/api/v1/updateCurriculum`,
        curriculumData,
        {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem('token')}`
            }
        }
    )
    return response.data.data
}

