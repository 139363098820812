// src/api/auth.js
import axios from 'axios';
import { DEV_BASE_URL } from '../../utils/constants/routes';

export const loginApi = async (credentials) => {
  const response = await axios.post(`${DEV_BASE_URL}/auth/login`, {
    email: credentials.email,
    password: credentials.password
  });

  return response.data;
};

export const fetchUserProfile = async (token) => {
  const response = await axios.get(`${DEV_BASE_URL}/api/v1/user/userDetails`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  return response.data;
};
