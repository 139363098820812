import React, { useEffect, useState } from "react";
import "./activityDetails.scss";
import {
  activtyMediaDetails,
  getClassStatus,
  publishMediaService,
  deleteMediaService,
  updateClassStatusService,
  autoPost,
} from "./activityDetailsService";
import { unTagStudents } from "./studentListModal/studentListModalService.js";
import { useParams } from "react-router-dom";
import {
  DeleteLogbook,
  DeleteMedia,
  RevertMedia,
  setActivityMedias,
  SetDeleteActivityMedia,
  SetStatus,
} from "./activityDetailsSlice";
import { setCurrentTab } from "./activityDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button";
import {
  CancelLogo,
  Close,
  DeleteLogo,
  HighLightsLogo,
  PlayButton,
  RevertLogo,
} from "../../../assets/images/svg";
import SpotlightVivaItem from "./audioPlayer.js/AudioPlayer";
import StudentsListModal from "./studentListModal/StudentsListModal";
import AddActivityMedia from "../activityMedia/AddActivityMedia.js";
import Alert from "../../../components/Alerts/Alert.js";
import ConfirmationModal from "../../../components/Modal/ConfirmationModal.js";

const ActivityDetailsPage = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("evidence");
  const token = localStorage.getItem("token");
  const { id } = useParams();
  const [showRight, setShowRight] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [delSelectedMedia, setDelSelectedMedia] = useState(null);
  const [mediaSelected, setMediaSelected] = useState([]);
  const [mediaViewer, setMediaViewer] = useState([]);
  const [studentsName, setStudentsName] = useState(null);
  const [uploadActivityStatus, setUploadActivityStatus] = useState(0);
  const [tagStatus, setTagStatus] = useState(null);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMesage] = useState("");
  const [names, setName] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [hoveredMediaId, setHoveredMediaId] = useState(null);
  const [modalStatus, setModalStatus] = useState(null);
  const [callActivity, setCallActivity] = useState(true);
  const [viewerMediaSelected, setViewerMediaSelected] = useState([]);
  const [lastMediaSelected, setLastMediaSelected] = useState([])
  const [currentViewerScope, setCurrentViewerScope] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const [selectedStudentName, setSelectedStudentName] = useState(null)



  const handleSelectedMedia = (currMedia, val, name) => {
    if (val) {
      setMediaViewer((prevMedias) => [...prevMedias, currMedia]);
    } else {
      setMediaViewer((prevMedias) =>
        prevMedias.filter((media) => media.media_id != currMedia.media_id)
      );
    }
  };

  const handleConfirmModal = () => {
    if (currentStatus === "Complete") {
      setConfirmationTitle("Mark as Completed");
      setConfirmationMessage(
        "Are you sure you want to mark this activity as completed?"
      );
    } else if (currentStatus == "Submit") {
      setConfirmationTitle("Mark as Submitted");
      setConfirmationMessage(
        "Are you sure you want to mark this activity as Submitted ?"
      );
    } else if (currentStatus == "Delete") {
      setConfirmationTitle("Delete");
      setConfirmationMessage(
        "Are you sure you want to delete the selected Medias ?"
      );
    } else if (currentStatus == "Approve") {
      setConfirmationTitle("Mark as Approve");
      setConfirmationMessage(
        "Are you sure you want to mark this activity as Approved ?"
      );
    } else if (currentStatus == "Publish") {
      setConfirmationTitle("Mark as Published");
      setConfirmationMessage(
        "Are you sure you want to mark this activity as Published ?"
      );
    }
    setShowConfirmationModal(true);
  };

  const handleClick = async () => {
    if (currentStatus === "Complete") {
      dispatch(SetStatus("Submit"));
      let statusData = await updateClassStatusService(token, id, "completed");
      // alert(statusData.message)
      setShowAlert(true);
      setShowConfirmationModal(false);
      setAlertMesage("Activity Marked as Completed");
    } else if (currentStatus == "Submit") {
      // setMessage("Are you sure want to mark it as Completed")
      dispatch(SetStatus("Publish"));
      let statusData = await updateClassStatusService(token, id, "submitted");
      setShowAlert(true);
      setAlertMesage("Activity Marked as Submitted");
      setShowConfirmationModal(false);
      setUploadActivityStatus(!uploadActivityStatus);
    } else if (currentStatus === "Delete") {
      let data = await deleteMediaService(token, deleteMediaIds);
      if (data.status) {
        dispatch(SetStatus("Approve"));
        dispatch(SetDeleteActivityMedia());
      }
      setShowConfirmationModal(false);
    } else if (currentStatus === "Publish") {
      let data = await publishMediaService(token, deleteMediaIds);
      if (data.status) {
        dispatch(SetStatus("Finish"));
        dispatch(SetDeleteActivityMedia());
        let statusData = await updateClassStatusService(token, id, "published");
        setShowAlert(true);
        setAlertMesage("Activity Marked as Published");
        setShowConfirmationModal(false);

        // if (data.status) await autoPost(token, id, "teacher_portfolio");
      }
    } else if (currentStatus === "Approve") {
      let data = await publishMediaService(token, deleteMediaIds);
      if (data.status) {
        dispatch(SetStatus("Publish"));
        dispatch(SetDeleteActivityMedia());
        let statusData = await updateClassStatusService(token, id, "approved");
        setShowAlert(true);
        setAlertMesage("Activity Marked as Approved");
        setShowConfirmationModal(false);
      }
    }
  };

  const handleTagUntag = () => {
    console.log("inside tag  and untag");
    if (mediaViewer[0].user_id) {
      setModalStatus("checkBoxUnTag");
    }
    else{
      setModalStatus("checkBoxTag")
    }
    setShowModal(true);
  };

  const handleShowRight = () => {
    dispatch(SetDeleteActivityMedia());
    setUploadActivityStatus(!uploadActivityStatus);

    setShowRight(false);
  };

  const currentTab = useSelector((state) => state.activityDetails.currentTab);
  let allMedia = useSelector(
    (state) => state.activityDetails.currentActivityMedia
  );
  let deleteMedia = useSelector(
    (state) => state.activityDetails.deleteActivityMedia
  );
  const currentStatus = useSelector(
    (state) => state.activityDetails.currentStatus
  );
  const deleteMediaIds = useSelector(
    (state) => state.activityDetails.deleteMediaIds
  );

  useEffect(() => {
    const fetchActivityMedia = async () => {
      if (!showModal || uploadActivityStatus) {
        const data = await activtyMediaDetails(token, id);
        console.log(data);
        dispatch(SetDeleteActivityMedia());
        dispatch(setActivityMedias(data));
        let classStatus = await getClassStatus(token, id);
        console.log(classStatus);
        if (classStatus == "submitted") dispatch(SetStatus("Approve"));
        else if (classStatus == "approved") dispatch(SetStatus("Publish"));
        else if (classStatus == "published") dispatch(SetStatus("Finish"));
        else if (classStatus == "inprogress") dispatch(SetStatus("Complete"));
        else if (classStatus == "completed") dispatch(SetStatus("Submit"));
      }
    };
    fetchActivityMedia();
    console.log("first");
  }, [dispatch, token, callActivity, uploadActivityStatus]);

  const renderMediaItems = (mediaList, name, type, revert) => {
    const getMediaKey = (media) => `${media.media_id}-${media.user_id}`;
    return (
      <>
        <div className="logbook-media-item">
          <p>{name}</p>
          <div
            className={`logbookAudio-media-item ${
              type == "audio" ? "viva-tile-container" : ""
            }`}
          >
            {mediaList?.map((media, index) => {
              const mediaKey = getMediaKey(media);
              const isHovered = hoveredMediaId === mediaKey;
              if (type == "image") {
                {
                  return media.media_category == "video" ? (
                    <div
                      className={`vid-container ${lastMediaSelected.length > 0 && media.user_id == lastMediaSelected[0].user_id && media.media_id == lastMediaSelected[0].media_id ? "highlight-media" : ""}`}
                      onMouseEnter={() => setHoveredMediaId(mediaKey)}
                      onMouseLeave={() => setHoveredMediaId(null)}
                    >
                      <video
                        height="100px"
                        width="100px"
                        src={media.media_url}
                        poster={media.thumbnail_media_url}
                        onClick={() => {
                          if (
                            currentStatus == "Complete" ||
                            currentStatus == "Submit"
                          ) {
                            // setMediaSelected(media)
                            let currMediaCLick = [];
                            currMediaCLick.push(media);
                            setMediaSelected(currMediaCLick);
                            if (media.user_id) {
                              setModalStatus("untag");
                              setCurrentViewerScope(mediaList)
                              setCurrentIndex(index)
                              setShowModal(true);
                            } else {
                              setModalStatus("tag");
                              setCurrentViewerScope(mediaList)
                              setShowModal(true);
                              setCurrentIndex(index)
                              setDelSelectedMedia(media.media_id);
                            }
                          } else if (currentStatus == "Approve" || currentStatus == "Delete") {
                            setCurrentViewerScope(mediaList)
                            setModalStatus("delete");
                            setSelectedStudentName(name)
                            setCurrentIndex(index)
                            setShowModal(true);
                          }
                          else if (currentStatus == "Publish"){
                            setCurrentViewerScope(mediaList)
                            setModalStatus("publish");
                            setSelectedStudentName(name)
                            setCurrentIndex(index)
                            setShowModal(true);
                          }
                          else{
                            setModalStatus("finish")
                            setShowModal(true);
                            setCurrentIndex(index)
                            setCurrentViewerScope(mediaList)
                          }
                        }}
                      ></video>
                      <div
                        className="status-logo"
                        onClick={() => {
                          if (currentStatus == "Approve" && !revert)
                            dispatch(SetStatus("Delete"));
                          if (revert) {
                            dispatch(
                              RevertMedia({
                                media: media,
                                media_type: media.media_category,
                                student: name,
                              })
                            );
                          } else {
                            dispatch(
                              DeleteMedia({
                                media: media,
                                media_type: media.media_category,
                                student: name,
                              })
                            );
                          }
                        }}
                      >
                        {isHovered && revert && (currentStatus === "Delete" || currentStatus === "Approve") && <RevertLogo/>}
                        {isHovered && revert && currentStatus === "Publish" && <CancelLogo/>}
                        {isHovered &&
                          (currentStatus === "Delete" ||
                            currentStatus === "Approve") && !revert && <DeleteLogo />}
                        {isHovered && currentStatus === "Publish" && !revert && (
                          <HighLightsLogo />
                        )}
                      </div>
                      {(currentStatus == "Complete" ||
                        currentStatus == "Submit") && (
                        <input
                          type="checkbox"
                          checked={mediaViewer.some(
                            (m) =>
                              m.media_id == media.media_id &&
                              m.user_id == media.user_id
                          )}
                          className="img-checkbox"
                          onChange={(e) =>
                            handleSelectedMedia(media, e.target.checked)
                          }
                        />
                      )}
                    </div>
                  ) : (
                    <div
                      className={`img-container ${lastMediaSelected.length > 0 && media.user_id == lastMediaSelected[0].user_id && media.media_id == lastMediaSelected[0].media_id ? "highlight-media" : ""}`}
                      onMouseEnter={() => setHoveredMediaId(mediaKey)}
                      onMouseLeave={() => setHoveredMediaId(null)}
                    >
                      <img
                        src={media.thumbnail_media_url ? media.thumbnail_media_url : media.media_url}
                        height="100px"
                        width="100px"
                        alt={`media-${index}`}
                        onClick={() => {
                          if (
                            currentStatus == "Complete" ||
                            currentStatus == "Submit"
                          ) {
                            let currMediaCLick = [];
                            currMediaCLick.push(media);
                            setMediaSelected(currMediaCLick);
                            if (media.user_id) {
                              setModalStatus("untag");
                              setCurrentViewerScope(mediaList)
                              setCurrentIndex(index)
                              setShowModal(true);
                            } else {
                              setModalStatus("tag");
                              console.log(currentStatus);
                              setCurrentViewerScope(mediaList)
                              setCurrentIndex(index)
                              setShowModal(true);
                              setDelSelectedMedia(media.media_id);
                            }
                          } else if (currentStatus == "Approve" || currentStatus == "Delete") {
                            setCurrentViewerScope(mediaList)
                            setModalStatus("delete");
                            setSelectedStudentName(name)
                            setCurrentIndex(index)
                            setShowModal(true);
                          }
                          else if (currentStatus == "Publish"){
                            setCurrentViewerScope(mediaList)
                            setModalStatus("publish");
                            setSelectedStudentName(name)
                            setCurrentIndex(index)
                            setShowModal(true);
                          }
                          else{
                            setModalStatus("finish")
                            setShowModal(true);
                            setCurrentIndex(index)
                            setCurrentViewerScope(mediaList)
                          }
                        }}
                      />
                      <div
                        className="status-logo"
                        onClick={() => {
                          if (currentStatus == "Approve" && !revert)
                            dispatch(SetStatus("Delete"));
                          if (revert) {
                            dispatch(
                              RevertMedia({
                                media: media,
                                media_type: media.media_category,
                                student: name,
                              })
                            );
                          } else {
                            dispatch(
                              DeleteMedia({
                                media: media,
                                media_type: media.media_category,
                                student: name,
                              })
                            );
                          }
                        }}
                      >
                        {isHovered && revert && (currentStatus === "Delete" || currentStatus === "Approve") && <RevertLogo/>}
                        {isHovered && revert && currentStatus === "Publish" && <CancelLogo/>}
                        {isHovered &&
                          (currentStatus === "Delete" ||
                            currentStatus === "Approve") && !revert && <DeleteLogo />}
                        {isHovered && currentStatus === "Publish" && !revert && (
                          <HighLightsLogo />
                        )}
                      </div>
                      {(currentStatus == "Complete" ||
                        currentStatus == "Submit") && (
                        <input
                          type="checkbox"
                          checked={mediaViewer.some(
                            (m) =>
                              m.media_id == media.media_id &&
                              m.user_id == media.user_id
                          )}
                          className="img-checkbox"
                          onChange={(e) =>
                            handleSelectedMedia(media, e.target.checked)
                          }
                        />
                      )}
                    </div>
                  );
                }
              } else if (type === "audio") {
                return (
                  <div
                    className="viva-preview-container"
                    onMouseEnter={() => setHoveredMediaId(mediaKey)}
                    onMouseLeave={() => setHoveredMediaId(null)}
                  >
                    <SpotlightVivaItem
                      media_url={media.media_url}
                      onClick={() => {
                        if (
                          currentStatus == "Complete" ||
                          currentStatus == "Submit"
                        ) {
                          let currMediaCLick = [];
                          currMediaCLick.push(media);
                          setMediaSelected(currMediaCLick);
                          if (media.user_id) {
                              setModalStatus("untag");
                              setCurrentViewerScope(mediaList)
                              setCurrentIndex(index)
                              setShowModal(true);
                          } else {
                              setModalStatus("tag");
                              console.log(currentStatus);
                              setCurrentViewerScope(mediaList)
                              setCurrentIndex(index)
                              setShowModal(true);
                              setDelSelectedMedia(media.media_id);
                          }
                        } else if (currentStatus == "Approve" || currentStatus == "Delete") {
                          setCurrentViewerScope(mediaList)
                          setModalStatus("delete");
                          setSelectedStudentName(name)
                          setCurrentIndex(index)
                          setShowModal(true);
                        }
                        else if (currentStatus == "Publish"){
                          setCurrentViewerScope(mediaList)
                          setModalStatus("publish");
                          setSelectedStudentName(name)
                          setCurrentIndex(index)
                          setShowModal(true);
                        }
                      }}
                      filename={media.filename}
                      currentAudio={currentAudio}
                      setCurrentAudio={setCurrentAudio}
                    />
                    <div
                      className="status-logo-viva"
                      onClick={() => {
                        if (currentStatus == "Approve" && !revert)
                          dispatch(SetStatus("Delete"));
                        if (revert) {
                          dispatch(
                            RevertMedia({
                              media: media,
                              media_type: media.media_category,
                              student: name,
                            })
                          );
                        } else {
                          dispatch(
                            DeleteMedia({
                              media: media,
                              media_type: media.media_category,
                              student: name,
                            })
                          );
                        }
                      }}
                    >
                      
                      {(isHovered && revert && (currentStatus === "Delete" || currentStatus === "Approve")) && <RevertLogo/>}
                      {isHovered && revert && currentStatus === "Publish" && <CancelLogo/>}
                      {isHovered &&
                        (currentStatus === "Delete" ||
                          currentStatus === "Approve") && !revert  && <DeleteLogo />}
                      {isHovered && currentStatus === "Publish" && !revert && (
                        <HighLightsLogo />
                      )}
                    </div>
                    {(currentStatus == "Complete" ||
                      currentStatus == "Submit") && (
                      <input
                        type="checkbox"
                        checked={mediaViewer.some(
                          (m) =>
                            m.media_id == media.media_id &&
                            m.user_id == media.user_id
                        )}
                        className="viva-checkbox"
                        onChange={(e) =>
                          handleSelectedMedia(media, e.target.checked)
                        }
                      />
                    )}
                  </div>
                );
              }
            })}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="activity-details-page">
      {showConfirmationModal && (
        <ConfirmationModal
          title={confirmationTitle}
          message={confirmationMessage}
          showConfirmationModal={showConfirmationModal}
          setShowConfirmationModal={setShowConfirmationModal}
          handleConfirmClick={handleClick}
        />
      )}
      {showAlert && (
        <Alert
          message={alertMessage}
          type="success"
          onClose={() => setShowAlert(false)}
        />
      )}
      <div className={`activity-left ${!showRight ? "showRight" : ""}`}>
        <div className="tabs page-tab">
          <div
            className={`tab ${activeTab === "details" ? "tab-active" : ""}`}
            onClick={() => setActiveTab("details")}
          >
            Details
          </div>
          <div
            className={`tab ${activeTab === "evidence" ? "tab-active" : ""}`}
            onClick={() => setActiveTab("evidence")}
          >
            Evidence
          </div>
        </div>
        {/* TODO://MOVE TO anoter sub file */}
        {activeTab === "details" && (
          <div className="tab-content details-content p-3">Details Content</div>
        )}
        {activeTab === "evidence" && (
          //TODO: Move to another sub file
          <div className="tab-content evidence-content">
            <div className="tabs">
              <div
                className={`tab ${currentTab === "media" ? "active" : ""}`}
                onClick={() => {
                  setMediaSelected([]);
                  dispatch(setCurrentTab("media"));
                }}
              >
                Media
              </div>
              <div
                className={`tab ${currentTab === "logbook" ? "active" : ""}`}
                onClick={() => {
                  setMediaSelected([]);
                  dispatch(setCurrentTab("logbook"));
                }}
              >
                Logbook
              </div>
              <div
                className={`tab ${currentTab === "viva" ? "active" : ""}`}
                onClick={() => {
                  setMediaSelected([]);
                  dispatch(setCurrentTab("viva"));
                }}
              >
                Viva
              </div>
            </div>
            {currentTab === "media" && (
              <div
                className={`tab-content inner-tab-content ${
                  (currentStatus === "Approve" ||
                    currentStatus == "Complete" ||
                    currentStatus == "Submit") &&
                  "show-button"
                }`}
              >
                {(currentStatus == "Complete" || currentStatus == "Submit") && (
                  <AddActivityMedia
                    currentTab={currentTab}
                    uploadActivityStatus={uploadActivityStatus}
                    setUploadActivityStatus={setUploadActivityStatus}
                  />
                )}
                <div className="media-container">
                  {Object.keys(allMedia.media).map(
                    (name, index) =>
                      allMedia.media[name].length > 0 && (
                        <div
                          className={name == "untagged media" ? "col-12" : ""}
                          key={index}
                        >
                          {renderMediaItems(
                            allMedia.media[name],
                            name,
                            "image"
                          )}
                          {name == "untagged media" ? <br /> : <></>}
                        </div>
                      )
                  )}
                </div>
              </div>
            )}
            {currentTab === "logbook" && (
              <div className="tab-content inner-tab-content">
                {(currentStatus == "Complete" || currentStatus == "Submit") && (
                  <AddActivityMedia
                    currentTab={currentTab}
                    uploadActivityStatus={uploadActivityStatus}
                    setUploadActivityStatus={setUploadActivityStatus}
                  />
                )}
                <div className="logbook-container">
                  {Object.keys(allMedia.logbook).map(
                    (name, index) =>
                      allMedia.logbook[name].length > 0 && (
                        <div
                          className={name == "untagged media" ? "col-12" : ""}
                          key={index}
                        >
                          {renderMediaItems(
                            allMedia.logbook[name],
                            name,
                            "image"
                          )}
                        </div>
                      )
                  )}
                </div>
              </div>
            )}
            {currentTab === "viva" && (
              <div className="tab-content inner-tab-content">
                {(currentStatus == "Complete" || currentStatus == "Submit") && (
                  <AddActivityMedia
                    currentTab={currentTab}
                    uploadActivityStatus={uploadActivityStatus}
                    setUploadActivityStatus={setUploadActivityStatus}
                  />
                )}
                <div className="viva-container">
                  {Object.keys(allMedia.viva).map(
                    (name, index) =>
                      allMedia.viva[name].length > 0 && (
                        <div
                          className={name == "untagged media" ? "col-12" : ""}
                          key={index}
                        >
                          {renderMediaItems(allMedia.viva[name], name, "audio")}
                        </div>
                      )
                  )}
                </div>
              </div>
            )}
            {(currentStatus === "Approve" ||
              currentStatus == "Complete" ||
              currentStatus == "Submit") && (
              <div className="approved-btn-container">
                {mediaViewer.length > 0 ? (
                  <Button
                    label={
                      mediaViewer[0]?.user_id
                        ? "Untag / Delete"
                        : "Tag / Delete"
                    }
                    onClick={handleTagUntag}
                    backgroundColor="#EA983E"
                    textColor="white"
                  />
                ) : (
                  <Button
                    label={currentStatus}
                    onClick={handleConfirmModal}
                    backgroundColor="#EA983E"
                    textColor="white"
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {(showRight || deleteMediaIds.length > 0) && (
        <div className="activity-right">
          <div className="deleted-media-wrapper">
            <div className="delete-header">
              <h2 className="title">
                {currentStatus === "Approve"
                  ? "Publish"
                  : currentStatus === "Delete"
                  ? "Delete"
                  : "Highlights"}
              </h2>
              <Button
                backgroundColor="#fff"
                textColor="#000"
                label="X"
                className="close-btn"
                onClick={handleShowRight}
              ></Button>
            </div>
            <div
              className={`deleted-media ${
                currentStatus !== "Finish" && "show-button"
              }`}
            >
              <div className="media-data">
                <h5 className="subTitle">Media</h5>
                <div className="media-wrapper">
                  {deleteMedia &&
                    deleteMedia?.media &&
                    Object.keys(deleteMedia?.media)?.map((name, index) => {
                      return (
                        <div key={index}>
                          {renderMediaItems(
                            deleteMedia?.media[name],
                            name,
                            "image",
                            true
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="media-data">
                <h5 className="subTitle">Logbook</h5>
                <div className="media-wrapper">
                  {deleteMedia &&
                    deleteMedia?.logbook &&
                    Object.keys(deleteMedia?.logbook)?.map((name, index) => {
                      return (
                        <div key={index}>
                          {renderMediaItems(
                            deleteMedia?.logbook[name],
                            name,
                            "image",
                            true
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="media-data">
                <h5 className="subTitle">Viva</h5>
                <div className="media-wrapper">
                  {deleteMedia &&
                    deleteMedia?.viva &&
                    Object.keys(deleteMedia?.viva)?.map((name, index) => {
                      return (
                        <div key={index}>
                          {renderMediaItems(
                            deleteMedia?.viva[name],
                            name,
                            "audio",
                            true
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            {currentStatus !== "Finish" && (
              <div className="deleted-btn-container">
                <Button
                  label={currentStatus}
                  onClick={handleConfirmModal}
                  backgroundColor="#EA983E"
                  textColor="white"
                />
              </div>
            )}
          </div>
        </div>
      )}

      {showModal && (
        <StudentsListModal
          displayModal={showModal}
          setShowModal={setShowModal}
          mediaSelected={mediaSelected}
          setMediaSelected={setMediaSelected}
          callActivity={callActivity}
          setCallActivity={setCallActivity}
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
          viewerMediaSelected={viewerMediaSelected}
          mediaViewer = {mediaViewer}
          setMediaViewer = {setMediaViewer}
          setLastMediaSelected = {setLastMediaSelected}
          currentViewerScope = {currentViewerScope}
          setCurrentViewerScope = {setCurrentViewerScope}
          currentIndex = {currentIndex}
          selectedStudentName = {selectedStudentName}
          setSelectedStudentName = {setSelectedStudentName}
        />
      )}
    </div>
  );
};

export default ActivityDetailsPage;
