import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  assessmentDetails: [],
  selectedAssessment: {},
  assessmentDetailsMultipleFileTypes: {
    "Show your Learning": [],
    viva: [],
  },
};

const assessmentDetailSlice = createSlice({
  name: "assessments",
  initialState,
  reducers: {
    setAssessments: (state, action) => {
      let data = action.payload;
      let temp_assessment = [];
      data?.map((ele) => {
        temp_assessment.push(ele);
      });
      state.assessmentDetails = temp_assessment;
    },

    setAssessmentsMultipleFileTypes: (state, action) => {
      state.assessmentDetailsMultipleFileTypes = action.payload;
    },

    setSelectedAssessment: (state, action) => {
      state.selectedAssessment = action.payload;
    },
  },
});

export const {
  setAssessments,
  setAssessmentsMultipleFileTypes,
  setSelectedAssessment,
} = assessmentDetailSlice.actions;

export default assessmentDetailSlice.reducer;
