import React, { forwardRef } from 'react';
import Button from '../Button';
import { CrossLogo } from '../../assets/images/svg';
import './confirmationModal.scss';

const ConfirmationModal = forwardRef(({ title, message, showConfirmationModal, setShowConfirmationModal, handleConfirmClick }, ref) => {
  // Function to close the modal
  const handleClose = () => {
    setShowConfirmationModal(false);
  };

  return (
    <div className="confirmation-modal" ref={ref}>
      <div
        className={showConfirmationModal ? 'modal show modal-top fade d-block' : 'modal fade d-block'}
        style={{ backgroundColor: 'rgba(0,0,0,.5)' }}
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              <div className="close-modal" onClick={handleClose}>
                <CrossLogo />
              </div>
            </div>
            <div className="modal-body reject-modal-body">
              <p>{message}</p>
            </div>
            <div className="modal-footer">
              <Button label="Cancel" backgroundColor="white" textColor="#EA983E" onClick={handleClose} />
              <Button
                label="Proceed"
                backgroundColor="#EA983E"
                textColor="white"
                onClick={() => {
                  handleConfirmClick();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ConfirmationModal;
