// Generate image thumbnail and return it as a File object
// export const generateThumbnailImage = (file) => {
//     return new Promise((resolve, reject) => {
//       const reader = new FileReader();
//       reader.onload = function (e) {
//         const img = new Image();
//         img.onload = function () {
//           const canvas = document.createElement('canvas');
//           const ctx = canvas.getContext('2d');
//           const maxSize = 150; // Max thumbnail size
//           const scale = Math.min(maxSize / img.width, maxSize / img.height);
//           canvas.width = img.width * scale;
//           canvas.height = img.height * scale;
//           ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  
//           // Convert canvas to Blob and create a File object
//           canvas.toBlob(
//             (blob) => {
//               if (blob) {
//                 const thumbnailFile = new File([blob], `${file.name}-thumbnail.jpg`, {
//                   type: 'image/jpeg',
//                 });
//                 resolve(thumbnailFile); // Return File object
//               } else {
//                 reject(new Error('Failed to create thumbnail for image.'));
//               }
//             },
//             'image/jpeg',
//             0.7
//           );
//         };
//         img.src = e.target.result;
//         img.onerror = () => reject(new Error('Failed to load image.'));
//       };
//       reader.onerror = () => reject(new Error('Failed to read image file.'));
//       reader.readAsDataURL(file);
//     });
//   };

export const generateThumbnailImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        const img = new Image();
        img.onload = function () {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const maxSize = 150; // Thumbnail size
  
          const scale = Math.min(maxSize / img.width, maxSize / img.height);
          canvas.width = img.width * scale;
          canvas.height = img.height * scale;
  
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  
          // Debug: Show canvas content to ensure it is not empty
          console.log("Canvas Data URL:", canvas.toDataURL());
  
          canvas.toBlob((blob) => {
            if (blob) {
              console.log("Generated Blob:", blob);
              resolve(blob); // Return the thumbnail blob
            } else {
              reject(new Error('Failed to create thumbnail for image.'));
            }
          }, 'image/jpeg', 0.7);
        };
  
        img.src = e.target.result;
        img.onerror = () => reject(new Error('Failed to load image.'));
      };
      reader.onerror = () => reject(new Error('Failed to read image file.'));
      reader.readAsDataURL(file);
    });
  };
  
  




  // Generate video thumbnail and return it as a File object
  export const generateThumbnailVideo = (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata';
      const reader = new FileReader();
  
      reader.onload = function (e) {
        video.src = e.target.result;
        video.muted = true;
        video.play();
  
        video.onloadeddata = function () {
          video.currentTime = 1; // Capture thumbnail at 1 second
        };
  
        video.onseeked = function () {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const maxSize = 150; // Max thumbnail size
          const scale = Math.min(maxSize / video.videoWidth, maxSize / video.videoHeight);
          canvas.width = video.videoWidth * scale;
          canvas.height = video.videoHeight * scale;
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  
          // Convert canvas to Blob and create a File object
          canvas.toBlob(
            (blob) => {
              if (blob) {
                const thumbnailFile = new File([blob], `${file.name}-thumbnail.jpg`, {
                  type: 'image/jpeg',
                });
                resolve(thumbnailFile); // Return File object
              } else {
                reject(new Error('Failed to create thumbnail for video.'));
              }
            },
            'image/jpeg',
            0.7
          );
          video.pause(); // Pause the video after generating the thumbnail
        };
  
        video.onerror = () => reject(new Error('Failed to load video.'));
      };
  
      reader.onerror = () => reject(new Error('Failed to read video file.'));
      reader.readAsDataURL(file);
    });
  };
  