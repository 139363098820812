// src/features/login/loginSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  courses: []
};

const courseSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    setCourse: (state, action) => {
      state.courses = action.payload;
    }
  }
});

export const { setCourse } = courseSlice.actions;

export default courseSlice.reducer;
