import React from "react";
import Card from "../../../../../components/Card";

const AddHomeExtension = () => {
  return (
    <div className="add__home__extensions--page">
      <Card>
        <div className="add__home__extensions--content p-3">
          <h4>Add Home Extensions</h4>
        </div>
      </Card>
    </div>
  );
};

export default AddHomeExtension;
