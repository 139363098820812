import React, { useEffect } from "react";
import Card from "../../../../../components/Card";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Table from "../../../../../components/Table/Table";
import {
  closeModal,
  openModal,
} from "../../../../../components/Modal/ModalSlice";
import Modal from "../../../../../components/Modal/Modal";
import DynamicFormRenderer from "../../../../../components/Form/DynamicFormRenderer";
import {
  createSchoolActivityAPI,
  getSchoolActivityAPI,
} from "../../schoolMasterService";
import { activityMasterDetails } from "../../../../master/activity/activityMasterService";
import { activity } from "../../../../../utils/constants/routes";

const Index = () => {
  const [schoolActivitiesData, setSchoolActivitiesData] = useState([]);
  const [maxId, setMaxId] = useState(0);
  const [callApi, setCallApi] = useState(true);
  const schoolActivitiesStatusList = ["ALL", "Active", "Inactive"];

  const { id } = useParams();
  let schoolId = id;

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchSchoolActivities = async () => {
      const response = await getSchoolActivityAPI(schoolId);
      setSchoolActivitiesData(response);
    };

    fetchSchoolActivities();
  }, [schoolId, callApi]);

  useEffect(() => {
    let maxId = schoolActivitiesData.reduce((acc, curr) => {
      return curr.id > acc ? curr.id : acc;
    }, 0);
    setMaxId(maxId);
  }, [schoolActivitiesData]);

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width: "106px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <span
          className="text-truncate"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title={row.name}
        >
          {row.name}
        </span>
      ),
      width: "306px",
    },
    // {
    //   name: "Grade",
    //   selector: (row) => row.grade_name,
    //   sortable: true,
    //   width: "306px",
    // },
    // {
    //   name: "Section",
    //   selector: (row) => row.section_name,
    //   sortable: true,
    //   width: "306px",
    // },
    {
      name: "Description",
      selector: (row) => row.description,
      cell: (row) => (
        <span
          className="text-truncate"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title={row.description}
        >
          {row.description}
        </span>
      ),
      width: "306px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: false,
      cell: (row) => (
        <span className={`status ${row.status.toLowerCase()}`}>
          {row.status}
        </span>
      ),
      width: "110px",
    },
  ];

  return (
    <div className="school__master__activity--page">
      <Card>
        <div className="school__master__activity--list p-3 card-lg">
          <Table
            entity="school_activity_master"
            data={schoolActivitiesData}
            columns={columns}
            statusList={schoolActivitiesStatusList}
            buttonLabel="+ Activity"
            onAddClick={() => dispatch(openModal("activityModal"))}
            clickableRow={false}
          />
        </div>
      </Card>
      <ActivityModal id={maxId + 1} callApi={callApi} setCallApi={setCallApi} />
    </div>
  );
};

const ActivityModal = ({ id, callApi, setCallApi }) => {
  const [activitiesMasterOptions, setActivitiesMasterOptions] = useState([]);
  const school_id = useParams().id;
  const dispatch = useDispatch();
  let token = localStorage.getItem("token");

  useEffect(() => {
    const fetchActivityMaster = async () => {
      const data = await activityMasterDetails(token);
      let options = data.map((i) => ({
        value: i.id,
        label: i.name,
      }));
      setActivitiesMasterOptions(options);
    };

    fetchActivityMaster();
    setCallApi(true);
  }, [school_id, token, callApi]);

  const config = [
    {
      name: "id",
      label: "Id",
      type: "text",
      value: id,
      disabled: true,
    },
    {
      name: "activity_id",
      label: "Select Activity",
      type: "select",
      options: activitiesMasterOptions,
      required: true,
    },
    // {
    //   name: "status",
    //   label: "Status",
    //   type: "select",
    //   options: [
    //     {
    //       value: "active",
    //       label: "Active",
    //     },
    //     {
    //       value: "inactive",
    //       label: "Inactive",
    //     },
    //   ],
    //   required: true,
    // },
  ];

  const handleFormSubmit = async (data) => {
    let d = {
      school_id: Number(school_id),
      activity_id: Number(data.activity_id),
    };

    const response = await createSchoolActivityAPI(d);
    if (response.status) {
      alert(response.message);
      dispatch(closeModal());
      callApi ? setCallApi(false) : setCallApi(true);
    } else {
      alert("Something went wrong" + response.message);
      dispatch(closeModal());
      callApi ? setCallApi(false) : setCallApi(true);
    }
  };

  return (
    <Modal title="New Activity">
      <div className="d-flex flex-column">
        <DynamicFormRenderer
          formConfig={config}
          hasCancelBtn={false}
          submitBtnText="Save"
          handleSubmit={handleFormSubmit}
        />
      </div>
    </Modal>
  );
};

export default Index;
