import axios from "axios";
import { DEV_BASE_URL } from "../../utils/constants/routes";

export const courseDetails = async (token) => {
    console.log("coursesssss calllleeeed");
    console.log(token);
    
    const response = await axios.post(
        `${DEV_BASE_URL}/assessment/list`, 
        {}, // This is the request body, which is empty in this case
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
    );

    console.log(response.data.data);

    return response.data.data;
}

