import React from "react";
import Header from "../Header";
import Navigation from "../Navigation";
import SubHeader from "../SubHeader";
import "./index.scss";

const Layout = ({ children }) => {
  return (
    <main className="main-layout">
      <div className="row m-0 p-0">
        <div className="col-2 p-0">
          <Navigation />
        </div>
        <div className="col-10 p-0">
          <Header />
          <SubHeader />
          <div className="page-content">
            {children}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Layout;
