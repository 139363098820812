// src/features/login/loginSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profile: null
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
    }
  }
});

export const { setProfile } = loginSlice.actions;

export default loginSlice.reducer;
