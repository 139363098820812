// src/features/activity/activityList.js
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./courseList.scss";
import { setCourse } from "./courseSLice";
import { courseDetails } from "./courseService";
import Card from "../../components/Card";
import Table from "../../components/Table/Table";

const CourseList = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchCourses = async () => {
      console.log("Inside fetch coursesss");
      const data = await courseDetails(token);
      dispatch(setCourse(data));
    };

    fetchCourses();
  }, [dispatch, token]);

  const courses = useSelector((state) => state.course.courses);

  const courseStatusList = [
    "ALL",
    "SUBMITTED",
    "INCOMPLETE",
    "REJECTED",
    "APPROVED",
  ];

  const columns = [
    {
      name: "Member_name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Start date",
      selector: (row) => row.created_on,
      sortable: true,
    },
    {
      name: "Course name",
      selector: (row) => row.course_name,
      sortable: true,
    },
    {
      name: "Progress",
      selector: (row) => row.activity_id_rslv,
      sortable: true,
      cell: (row) => (
        <span>
          {row.lesson_completed_count}/{row.lesson_count}
        </span>
      ),
    },
    {
      name: "stage",
      selector: (row) => row.modified_on,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <span className={`status ${row.status.toLowerCase()}`}>
          {row.status}
        </span>
      ),
    },
  ];

  return (
    <div className="course-page">
      <Card>
        {/* <TableComponent data={activities} />
         */}
        <Table
          entity="assessments"
          data={courses}
          columns={columns}
          statusList={courseStatusList}
          hasAddBtn={false}
        />
      </Card>
    </div>
  );
};

export default CourseList;
