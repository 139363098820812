import axios from "axios";
import { DEV_BASE_URL } from "../../utils/constants/routes";

export const activityDetails = async (token) => {
    console.log(token)
    const response = await axios.get(`${DEV_BASE_URL}/api/v1/dashboard/class_list`, {
        headers : {
            'Authorization' : `Bearer ${token}`
        }
    });

    console.log(response.data.data.classes)

    return response.data.data.classes
}