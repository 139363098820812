import React, { useState } from "react";
import Card from "../../../../../components/Card";
import { separator } from "../detail/CourseMasterDetail";
import Table from "../../../../../components/Table/Table";
import FormInput from "../../../../../components/Form/FormInput";
import { AddLogo, DeleteLogo } from "../../../../../assets/images/svg";
import Button from "../../../../../components/Button";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  addCourseAssessment,
  getCourseAssessment,
  updateCourseAssessment,
} from "../../courseMasterService";
import { useEffect } from "react";

const CourseMasterContent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isNewEntry = useSelector((state) => state.courseMaster.isNewEntry);
  const allCourses = useSelector((state) => state.courseMaster.courses);
  const selectedCourse = allCourses.find(
    (course) => course.id === parseInt(id)
  );
  let course_id = id;
  const isEditable = useSelector(
    (state) => state.courseMaster.isCourseEditable
  );
  const isEditEntry = useSelector((state) => state.courseMaster.isEditEntry);

  const [isSaving, setIsSaving] = useState(false);
  const [values, setValues] = useState({});

  useEffect(() => {
    const fetchAssessments = async () => {
      {
        try {
          const response = await getCourseAssessment(course_id);
          if (response.status) {
            setValues((prev) => ({
              ...prev,
              assessment: response.data.length > 0 ? response.data : [""],
            }));
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
    !isNewEntry && fetchAssessments();
    isNewEntry && setValues((prev) => ({ ...prev, assessment: [""] }));
  }, [course_id]);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      cell: (row) => (
        <FormInput
          name="name"
          type="text"
          value={row.name ?? ""}
          onChange={(e) => {
            setValues((prev) => ({
              ...prev,
              assessment: prev.assessment?.map((assessment) =>
                assessment.name === row.name
                  ? { ...assessment, name: e.target.value }
                  : assessment
              ),
            }));
          }}
          required={true}
          disabled={!isEditable}
          className="text-truncate col-12"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
        />
      ),
      sortable: true,
      width: "344px",
    },
    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
      cell: (row) => (
        <FormInput
          name="type"
          type="select"
          value={row.type ?? ""}
          options={[
            {
              value: "",
              label: "Select type",
            },
            {
              value: "video_upload",
              label: "Video Upload",
            },
            {
              value: "viva",
              label: "Viva",
            },
            {
              value: "quiz",
              label: "Quiz",
            },
          ]}
          onChange={(e) => {
            setValues((prev) => ({
              ...prev,
              assessment: prev.assessment.map((assessment) =>
                assessment.name === row.name
                  ? { ...assessment, type: e.target.value }
                  : assessment
              ),
            }));
          }}
          required={true}
          disabled={!isEditable}
          className="text-truncate col-12"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
        />
      ),
      width: "344px",
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      cell: (row) => (
        <FormInput
          name="description"
          type="text"
          value={row.description ?? ""}
          onChange={(e) => {
            setValues((prev) => ({
              ...prev,
              assessment: prev.assessment.map((assessment) =>
                assessment.name === row.name
                  ? { ...assessment, description: e.target.value }
                  : assessment
              ),
            }));
          }}
          disabled={!isEditable}
          className="text-truncate col-12"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
        />
      ),
      width: "344px",
    },
    {
      name: "",
      cell: (row, i) => {
        return (
          <div
            className={`col-auto ${
              i !== values.assessment?.length - 1 ? "opacity-0" : ""
            }`}
            onClick={() =>
              isEditable &&
              i === values.assessment?.length - 1 &&
              setValues({ ...values, assessment: [...values.assessment, {}] })
            }
            key={i}
          >
            <AddLogo />
          </div>
        );
      },
      width: "32px",
    },
    // {
    //   name: "",
    //   cell: (row, i) =>
    //     values.assessment?.length > 1 && (
    //       <div
    //         className="col-auto "
    //         onClick={() => isEditable && handleAssessmentDelete(i)}
    //         key={i}
    //       >
    //         <DeleteLogo />
    //       </div>
    //     ),
    // },
  ];

  const handleAssessmentDelete = (i) => {
    const isConfirmed = window.confirm(
      `Are you sure you want to delete this assessment? ${i} `
    );

    if (isConfirmed) {
      setValues({
        ...values,
        assessment: values.assessment.filter((_, index) => index !== i),
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let maxOrder = values.assessment.reduce(
      (max, assessment) => (assessment.order > max ? assessment.order : max),
      0
    );

    values.assessment.map(async (a) => {
      if (a.id) {
        let payload = {
          type: a.type,
          course_id: course_id,
          order: a.order,
          name: a.name,
          description: a.description,
          status: a.status,
          course_assessment_id: a.id,
        };
        const response = await updateCourseAssessment(payload);
        console.log(response);
      } else {
        let payload = {
          type: a.type,
          course_id: course_id,
          order: maxOrder + 1,
          name: a.name,
          description: a.description,
          status: "published",
        };
        const response = await addCourseAssessment(payload);
      }
    });
    alert("Assessment Saved!");
    navigate(-1);
  };

  return (
    <Card>
      <div className="p-3 pt-0 card-lg">
        {isSaving ? (
          <h4>Saving...</h4>
        ) : (
          <>
            {separator("Assessment")}
            <form onSubmit={handleSubmit}>
              <Table
                entity="course_master--content-assessment"
                columns={columns}
                data={values.assessment}
                clickableRow={false}
              />

              {isEditable && (
                <div className="row container justify-content-end mt-3">
                  <Button
                    label={isEditEntry ? "Update" : "Save"}
                    backgroundColor={"#f6921e"}
                    textColor={"#fff"}
                    className="col-auto px-5"
                    type="submit"
                  />
                </div>
              )}
            </form>
          </>
        )}
      </div>
    </Card>
  );
};

export default CourseMasterContent;
