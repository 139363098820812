import axios from "axios";
import { DEV_BASE_URL } from "../../../../utils/constants/routes";


export const tagStudents = async (user_ids, media_ids) => {
    const response = await axios.post(`${DEV_BASE_URL}/api/v1/activity/tag`,
        {
            media_ids,
            user_ids
        },
        {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem('token')}`
            }
        }
    )
    return response.data
}

export const unTagStudents = async (user_ids, media_ids) => {
    const response = await axios.post(`${DEV_BASE_URL}/api/v1/activity/untag`,
        {
            media_ids,
            user_ids
        },
        {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem('token')}`
            }
        }
    )
    return response.data
}

export const deleteMedia = async (media_ids) => {
    const response = await axios.post(`${DEV_BASE_URL}/api/v1/activity/delete`,
        {
            media_ids
        },
        {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem('token')}`
            }
        }
    )
    return response.data
}