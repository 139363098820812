import React, { useState } from 'react'
import "./index.scss"
import { Notifications, Search } from '../../assets/images/svg'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const Header = () => {
  const profile = useSelector(state => state.login.profile)
  const navigate = useNavigate()

  const [showTooltip, setShowTooltip] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("token")
    setShowTooltip(false);
    navigate("/login");
  };

  return (
    <div className="header">
      <div className="school-logo">
        <img src={profile?.school_logo} alt="School Logo" className="logo-image" />
      </div>
      <div className="header-icons">
        <Search />
        <Notifications />
        
        <div className="profile-info">
          <img 
            src={profile?.profile_picture || 'default-profile-image-url'} 
            alt="Profile" 
            className="profile-image" 
            onClick={() => {
              console.log("cliledddd")
              setShowTooltip(!showTooltip)
              console.log(showTooltip)
            }} // Toggle tooltip
          />
          <span className="profile-name">{profile?.name || 'User Name'}</span>

          {/* Tooltip with Logout Button */}
          {showTooltip && (
            <div className="tooltip">
              <button onClick={handleLogout}>Logout</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header