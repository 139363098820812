import React, { useEffect, useState } from "react";
import Card from "../../../../../components/Card";
import DynamicFormRenderer from "../../../../../components/Form/DynamicFormRenderer";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setSchoolEditable, setSchoolMaster } from "../../schoolMasterSlice";
import {
  createSchoolAPI,
  getCurriculumDropdown,
  updateSchoolAPI,
} from "../../schoolMasterService";

const Detail = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [curriculumDropdown, setCurriculumDropdown] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const schoolDetail = useSelector((state) => state.schoolMaster.schools);
  let selectedSchoolDetail = schoolDetail.find(
    (school) => school.id === Number(id)
  );

  const maxId = schoolDetail.reduce((max, school) => {
    return school.id > max ? school.id : max;
  }, 0);

  const isEditable = useSelector(
    (state) => state.schoolMaster.isSchoolEditable
  );

  const isEditEntry = useSelector((state) => state.schoolMaster.isEditEntry);
  const isNewEntry = useSelector((state) => state.schoolMaster.isNewEntry);

  const idValue = isEditEntry
    ? selectedSchoolDetail?.id
    : isNewEntry
    ? maxId + 1
    : selectedSchoolDetail?.id;

  useEffect(() => {
    const fetchApis = async () => {
      const curr = await getCurriculumDropdown(id);
      setCurriculumDropdown(curr);
    };
    fetchApis();
  }, []);

  const config = [
    {
      name: "id",
      label: "Id",
      type: "text",
      value: idValue,
      disabled: true,
    },
    {
      name: "name",
      label: "Name",
      type: "text",
      value: selectedSchoolDetail?.name,
      disabled: !isEditable,
      required: true,
    },
    {
      name: "school_logo",
      label: "Logo",
      type: "file",
      disabled: !isEditable,
      required: isNewEntry,
    },
    {
      name: "curriculum_id",
      label: "Curriculum",
      type: "select",
      // options: ["ICSE", "CBSE", "IB", "Cambridge"],
      options: curriculumDropdown,
      value: selectedSchoolDetail?.curriculum_id,
      disabled: !isEditable,
      required: true,
    },
    {
      name: "description",
      label: "Description",
      type: "textarea",
      value: selectedSchoolDetail?.description,
      disabled: !isEditable,
    },
    {
      name: "status",
      label: "Status",
      type: "select",
      options: [
        {
          value: "active",
          label: "Active",
        },
        {
          value: "inactive",
          label: "Inactive",
        },
      ],
      value: selectedSchoolDetail?.status,
      disabled: !isEditable,
      required: true,
    },
  ];

  const onCancel = () => {
    dispatch(setSchoolEditable(false));
    navigate(-1);
  };

  const handleSubmit = (data) => {
    dispatch(setSchoolEditable(false));
    setIsSaving(true);
    setTimeout(async () => {
      if (isEditEntry) {
        const formData = new FormData();
        console.log(data);
        Object.keys(data).forEach((key) => {
          if (data[key]) formData.append(key, data[key]);
        });

        try {
          const response = await updateSchoolAPI(formData);
          if (response.status) {
            alert(response.message);
            setIsSaving(false);
            navigate(-1);
          } else {
            throw Error(response.message);
          }
        } catch (error) {
          alert(error.message);
          setIsSaving(false);
          navigate(-1);
        }

        //TODO: Add API call for updating school, either here or within the setSchoolMaster action
      } else if (isNewEntry) {
        console.log(data);
        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("description", data.description);
        formData.append("school_logo", data.school_logo);
        formData.append("curriculum_id", data.curriculum_id);
        formData.append("status", data.status);
        //TODO: Add API call for creating new school, either here or within the setSchoolMaster action
        try {
          const response = await createSchoolAPI(formData);
          console.log(response);
          if (response.status) {
            alert(response.message);
            setIsSaving(false);
            navigate(-1);
          } else {
            throw Error(response.message);
          }
        } catch (error) {
          alert(error.message);
          navigate(-1);
        }
      }
    }, 1000);
  };

  return (
    <Card>
      <div className="p-3 card-lg">
        {isSaving ? (
          "Saving..."
        ) : (
          <DynamicFormRenderer
            formConfig={config}
            handleSubmit={handleSubmit}
            hasCancelBtn={isEditable}
            hasButtons={isEditable}
            onCancel={onCancel}
            submitBtnText="Save"
          />
        )}
      </div>
    </Card>
  );
};

export default Detail;
