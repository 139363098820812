// src/features/activity/EditActivityModal.js
import React, { useState, useEffect } from "react";
import { CrossLogo } from "../../../assets/images/svg";
import Button from "../../../components/Button";
// import './editActivityModal.scss';
import "./addActivityModal.scss";
import { updateActivity } from "./addActivityModalService";
import FormInput from "../../../components/Form/FormInput";

const EditActivityModal = ({
  displayModal,
  setShowModal,
  activityData,
  callApi,
  setCallApi,
}) => {
  const [startTime, setStartTime] = useState(activityData.start_date || "");
  const [endTime, setEndTime] = useState(activityData.end_date || "");
  const [status, setStatus] = useState(activityData.status || "");
  const [statusDropdownOptions, setStatusDropdownOptions] = useState([
    { value: "scheduled", label: "SCHEDULED" },
    { value: "inprogress", label: "IN PROGRESS" },
    { value: "completed", label: "COMPLETED" },
    { value: "submitted", label: "SUBMITTED" },
    { value: "approved", label: "APPROVED" },
    { value: "published", label: "PUBLISHED" },
  ]);

  useEffect(() => {
    // Populate modal with initial data when opened
    setStartTime(activityData.start_time);
    setEndTime(activityData.end_time);
    setStatus(activityData.status);
  }, [activityData]);

  const handleClose = () => {
    setShowModal(false);
    setCallApi(!callApi);
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault()
    const response = await updateActivity(
      startTime,
      endTime,
      status,
      activityData.id
    );
    alert(response.message);

    // onSaveChanges(updatedData);
    handleClose();
  };

  const editActivityFormConfig = [
    {
      id: 0,
      name: "startTime",
      type: "datetime-local",
      label: "Start Time:",
      value: startTime,
      required: true,
      onChange: (e) => setStartTime(e.target.value),
    },
    {
      id: "endTime",
      name: "endTime",
      label: "End Time:",
      type: "datetime-local",
      value: endTime,
      required: true,
      onChange: (e) => setEndTime(e.target.value),
    },
    {
      id: "status",
      name: "status",
      type: "select",
      placeholder: "status",
      options: statusDropdownOptions,
      value: status,
      required: true,
      onChange: (e) => setStatus(e.target.value),
    },
  ];

  return (
    <div>
      <div
        className={
          displayModal ? "modal show fade d-block" : "modal fade d-block"
        }
        style={{ backgroundColor: "rgba(0,0,0,.5)" }}
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Activity</h5>
              <div className="close-modal" onClick={handleClose}>
                <CrossLogo />
              </div>
            </div>
            <div className="modal-body reject-modal-body">
              <form onSubmit={handleSaveChanges}>
                {editActivityFormConfig.map((item, index) => (
                  <FormInput key={index} {...item} />
                ))}
                <div className="modal-footer px-0">
                  <Button
                    label="Update"
                    backgroundColor="#f6921e"
                    textColor="white"
                    type="submit"
                  />
                </div>
              </form>
              {/* <label for="startTime">Start Time:</label>
              <input
                type="datetime-local"
                id="startTime"
                name="startTime"
                onChange={(e) => {
                  setStartTime(e.target.value);
                }}
              />
              <label for="endTime">End Time:</label>
              <input
                type="datetime-local"
                id="endTime"
                name="endTime"
                onChange={(e) => {
                  setEndTime(e.target.value);
                }}
              />
              <label htmlFor="status">Status:</label>
              <select
                id="status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="scheduled">SCHEDULED</option>
                <option value="inprogress">INPROGRESS</option>
                <option value="completed">COMPLETED</option>
                <option value="submitted">SUBMITTED</option>
                <option value="approved">APPROVED</option>
                <option value="published">PUBLISHED</option>
              </select> */}
            </div>
            {/* <div className="modal-footer">
              <Button
                label="Save Changes"
                backgroundColor="#EA983E"
                textColor="white"
                onClick={handleSaveChanges}
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditActivityModal;
