import React, { useEffect, useState } from "react";
import Card from "../../../components/Card";
import { useDispatch, useSelector } from "react-redux";
import { schoolMasterDetails } from "./schoolMasterService";
import {
  setIsNewEntry,
  setIsEditEntry,
  setSchoolEditable,
  setSchoolMaster,
} from "./schoolMasterSlice";
import Table from "../../../components/Table/Table";
import { EditLogo } from "../../../assets/images/svg";
import { useNavigate } from "react-router-dom";
import "./index.scss";

const List = () => {
  const [callApi, setCallApi] = useState(true);

  const dispatch = useDispatch();

  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSchoolMaster = async () => {
      const data = await schoolMasterDetails(token);
      dispatch(setSchoolMaster(data));
    };

    fetchSchoolMaster();
    setCallApi(true);
  }, [dispatch, token, callApi]);

  const onAddClick = () => {
    dispatch(setSchoolEditable(true));
    dispatch(setIsNewEntry(true));
    dispatch(setIsEditEntry(false));
    navigate("add");
  };

  const schools = useSelector((state) => state.schoolMaster.schools);
  const isEditable = useSelector(
    (state) => state.schoolMaster.isSchoolEditable
  );

  const schoolStatusList = ["ALL", "Active", "Inactive"];

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width: "60px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <span
          className="text-truncate"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title={row.name}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`${row.id}`);
          }}
        >
          {row.name}
        </span>
      ),
      width: "270px",
    },
    {
      name: "Logo",
      selector: (row) => row.school_logo,
      cell: (row) => (
        <img
          src={row.school_logo}
          alt="logo"
          className="img-fluid school__master-logos"
          onClick={(e) => {
            e.stopPropagation();
            navigate(`${row.id}`);
          }}
        />
      ),
      width: "270px",
    },
    {
      name: "Curriculum",
      selector: (row) => row.curriculum_name,
      sortable: true,
      width: "120px",
    },

    {
      name: "Description",
      selector: (row) => row.description,
      cell: (row) => (
        <span
          className="text-truncate"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title={row.description}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`${row.id}`);
          }}
        >
          {row.description}
        </span>
      ),
      width: "270px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: false,
      cell: (row) => (
        <span className={`status ${row.status.toLowerCase()}`}>
          {row.status}
        </span>
      ),
      width: "110px",
    },
    {
      name: "",
      // button: true,
      cell: (row) => (
        <button
          className="btn"
          onClick={() => {
            dispatch(setSchoolEditable(true));
            dispatch(setIsEditEntry(true));
            dispatch(setIsNewEntry(false));
            navigate(`${row.id}`);
          }}
        >
          <EditLogo />
        </button>
      ),
    },
  ];

  const onRowClick = (row) => {
    dispatch(setIsEditEntry(false));
    dispatch(setIsNewEntry(false));
  };

  return (
    <div className="school__master--page">
      <Card>
        <div className="school__master--list p-3 pt-2">
          <Table
            entity="school-master"
            data={schools}
            columns={columns}
            statusList={schoolStatusList}
            onAddClick={onAddClick}
            buttonLabel="+ School"
            onRowClick={onRowClick}
          />
        </div>
      </Card>
    </div>
  );
};

export default List;
