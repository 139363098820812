import React, { useEffect, useState } from "react";
import Card from "../../../components/Card";
import { useDispatch, useSelector } from "react-redux";
// import { schoolMasterDetails } from "./schoolMasterService";
import {
  createBulkStudentAPI,
  createStudentAPI,
  fetchGradesFromAPI,
  fetchSectionsFromAPI,
  getSchoolSectionAPI,
  studentMasterDetails,
  updateStudentAPI,
} from "./studentMasterService";
import {
  setIsNewEntry,
  setIsEditEntry,
  setStudents,
  setIsStudentEditable,
  setSelectedStudent,
} from "./studentMasterSlice";
import Table from "../../../components/Table/Table";
import { EditLogo } from "../../../assets/images/svg";
import { useNavigate } from "react-router-dom";
import { closeModal, openModal } from "../../../components/Modal/ModalSlice";
import Modal from "../../../components/Modal/Modal";
import DynamicFormRenderer from "../../../components/Form/DynamicFormRenderer";
import {
  getSchoolGradeAPI,
  schoolMasterDetails,
} from "../school/schoolMasterService";
import "./index.scss";

const List = () => {
  const [callApi, setCallApi] = useState(true);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const dispatch = useDispatch();

  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStudentMaster = async () => {
      const data = await studentMasterDetails(page, rowsPerPage);
      // console.log(data.data.students);
      dispatch(setStudents(data.data.students));
      setTotalRows(data.data.total_records);
    };

    fetchStudentMaster();
    setCallApi(true);
  }, [dispatch, token, callApi, page, rowsPerPage, callApi]);

  const onAddClick = () => {
    dispatch(setIsStudentEditable(true));
    dispatch(setIsNewEntry(true));
    dispatch(setIsEditEntry(false));
    dispatch(openModal("studentModal"));
  };

  const students = useSelector((state) => state.studentMaster.students);
  const isEditable = useSelector(
    (state) => state.studentMaster.isStudentEditable
  );

  const studentStatusList = ["ALL", "Active", "Inactive"];

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width: "100px",
    },
    {
      name: "profile",
      selector: (row) => row.profile_url,
      cell: (row) => (
        <img
          src={row.profile_url}
          alt="profile"
          className="student__master--profile-img"
        />
      ),
      width: "70px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <span
          className="text-truncate"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title={row.name}
        >
          {row.name}
        </span>
      ),
      width: "228px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "228px",
    },
    {
      name: "School",
      selector: (row) => row.school_name,
      sortable: true,
      width: "228px",
    },

    {
      name: "Grade",
      selector: (row) => row.grade_name,

      width: "228px",
    },
    // {
    //   name: "Status",
    //   selector: (row) => row.status,
    //   sortable: false,
    //   cell: (row) => (
    //     <span className={`status ${row.status.toLowerCase()}`}>
    //       {row.status}
    //     </span>
    //   ),
    //   width: "100px",
    // },
    {
      name: "",
      cell: (row) => (
        <button
          className="btn"
          onClick={() => {
            dispatch(setIsStudentEditable(true));
            dispatch(setIsEditEntry(true));
            dispatch(setIsNewEntry(false));
            dispatch(setSelectedStudent(row));
            dispatch(openModal("studentModal"));
            // navigate(`${row.id}`);
          }}
        >
          <EditLogo />
        </button>
      ),
    },
  ];

  const onRowClick = (row) => {
    dispatch(setIsEditEntry(false));
    dispatch(setIsNewEntry(false));
  };

  return (
    <div className="student__master--page">
      <Card>
        <div className="student__master--list p-3 pt-2">
          <Table
            entity="student-master"
            data={students}
            columns={columns}
            statusList={studentStatusList}
            onAddClick={onAddClick}
            buttonLabel="+ Student"
            onRowClick={onRowClick}
            isServerSidePagination
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            paginationTotalRows={totalRows}
            clickableRow={false}
          />
        </div>
        <StudentModal callApi={callApi} setCallApi={setCallApi} />
      </Card>
    </div>
  );
};

const StudentModal = ({ callApi, setCallApi }) => {
  const dispatch = useDispatch();

  const [schoolDropdownOptions, setSchoolDropdownOptions] = useState([]);
  const [bulkInsert, setBulkInsert] = useState(0);

  const [gradeDropdownOptions, setGradeDropdownOptions] = useState([]);
  const [sectionDropdownOptions, setSectionDropdownOptions] = useState([]);
  const [selectedSchoolId, setSelectedSchoolId] = useState("");
  const [selectedGradeId, setSelectedGradeId] = useState("");

  const token = localStorage.getItem("token");

  const selectedStudent = useSelector(
    (state) => state.studentMaster.selectedStudent
  );
  const isEditEntry = useSelector((state) => state.studentMaster.isEditEntry);

  useEffect(() => {
    const fetchSchoolDropdownOptions = async () => {
      const data = await schoolMasterDetails(token);

      let d = [];
      data.map((item) => {
        d.push({
          value: item.id,
          label: item.name,
        });
      });
      setSchoolDropdownOptions(d);
    };

    fetchSchoolDropdownOptions();
  }, []);

  useEffect(() => {
    const setGradeAndSectionDropdown = async () => {
      if (isEditEntry) {
        setGradeDropdownOptions(
          await fetchGradesFromAPI(selectedStudent.school_id)
        );
        setSectionDropdownOptions(
          await fetchSectionsFromAPI(selectedStudent.grade_id)
        );
      }
    };
    setGradeAndSectionDropdown();
  }, [isEditEntry]);

  const formConfig = [
    {
      name: "id",
      label: "Id",
      type: "text",
      value: isEditEntry ? selectedStudent.id : "",
      disabled: true,
    },
    {
      name: "studentSchool",
      label: "School",
      type: "select",
      options: schoolDropdownOptions,
      value: isEditEntry ? selectedStudent.school_id : "",
      required: true,
      disabled: isEditEntry,
    },
    {
      name: "firstname",
      label: "First Name",
      type: "text",
      required: true,
      value: isEditEntry ? selectedStudent.first_name : "",
    },
    {
      name: "lastname",
      label: "Last Name",
      type: "text",
      value: isEditEntry ? selectedStudent.last_name : "",
      required: true,
    },
    {
      name: "email",
      label: "Email",
      type: "text",
      value: isEditEntry ? selectedStudent.email : "",
      required: true,
      pattern: "^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$",
    },
    {
      name: "file",
      label: "Profile Photo",
      type: "file",
      // required: true,
    },
    {
      name: isEditEntry ? "studentGrade_edit" : "studentGrade",
      label: "Grade",
      type: "select",
      options: isEditEntry ? gradeDropdownOptions : [],
      value: isEditEntry ? selectedStudent.grade_id : "",
      required: true,
      disabled: isEditEntry,
    },
    {
      name: isEditEntry ? "studentSection_edit" : "studentSection",
      label: "Section",
      type: "select",
      options: isEditEntry ? sectionDropdownOptions : [],
      value: isEditEntry ? selectedStudent.section_id : "",
      required: true,
      disabled: isEditEntry,
    },
  ];

  const bulkFormConfig = [
    {
      name: "id",
      label: "Id",
      type: "text",
      value: "",
      disabled: true,
    },
    {
      name: "studentSchool",
      label: "School",
      type: "select",
      options: schoolDropdownOptions,
      required: true,
    },
    {
      name: "studentGrade",
      label: "Grade",
      type: "select",
      options: [
        {
          value: "Standard I",
          label: "Standard I",
        },
        {
          value: "Standard II",
          label: "Standard II",
        },
        {
          value: "Standard III",
          label: "Standard III",
        },
      ],
      required: true,
    },
    {
      name: "studentSection",
      label: "Section",
      type: "select",
      options: [
        {
          value: "SC001",
          label: "A",
        },
        {
          value: "SC002",
          label: "B",
        },
      ],
      required: true,
    },
    {
      name: "file",
      label: "Excel file",
      type: "file",
      required: true,
    },
  ];

  const handleFormSubmit = async (data) => {
    // console.log(data);
    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("first_name", data.firstname);
    formData.append("last_name", data.lastname);
    formData.append("email", data.email);
    formData.append("school_id", Number(data.studentSchool));
    if (isEditEntry) {
      formData.append("grade_id", Number(data.studentGrade_edit));
      formData.append("section_id", Number(data.studentSection_edit));
    } else {
      formData.append("grade_id", Number(data.studentGrade));
      formData.append("section_id", Number(data.studentSection));
    }

    //log the formData
    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }

    // if (isEditEntry) {
    //   // log the formData
    //   console.log("Edit form data >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
    //   for (var pair of formData.entries()) {
    //     console.log(pair[0] + ", " + pair[1]);
    //   }
    // }

    const response = isEditEntry
      ? await updateStudentAPI(selectedStudent.id, formData)
      : await createStudentAPI(formData);

    if (response.status) {
      alert(response.message);
      callApi ? setCallApi(false) : setCallApi(true);
      dispatch(closeModal());
    } else {
      alert("Something went wrong");
      callApi ? setCallApi(false) : setCallApi(true);
      dispatch(closeModal());
    }
  };

  const handleBulkFormSubmit = async (data) => {
    // console.log(data);
    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("school_id", Number(data.studentSchool));
    formData.append("grade_id", Number(data.studentGrade));
    formData.append("grade_section_id", Number(data.studentSection));

    const response = await createBulkStudentAPI(formData);

    if (response.status) {
      alert(response.message);
      callApi ? setCallApi(false) : setCallApi(true);
      dispatch(closeModal());
    } else {
      alert("Something went wrong");
      callApi ? setCallApi(false) : setCallApi(true);
      dispatch(closeModal());
    }
    setBulkInsert(0);
  };

  const resetSlice = () => {
    dispatch(setIsNewEntry(false));
    dispatch(setIsEditEntry(false));
    dispatch(setIsStudentEditable(false));
  };
  const onCloseClick = () => {
    resetSlice();
  };

  return (
    <Modal
      title={isEditEntry ? "Edit Student" : "Add Student"}
      onCloseClick={onCloseClick}
    >
      {!isEditEntry && (
        <label htmlFor="">
          <input
            type="checkbox"
            name="bulkinsert"
            id="bulkinsert"
            onClick={(e) => {
              console.log(e.target.checked);
              if (e.target.checked) setBulkInsert(1);
              else setBulkInsert(0);
            }}
          />{" "}
          Bulk Insert
        </label>
      )}
      <div className="d-flex h-100 flex-column">
        <DynamicFormRenderer
          formConfig={bulkInsert == "0" ? formConfig : bulkFormConfig}
          hasCancelBtn={false}
          submitBtnText="Save"
          handleSubmit={
            bulkInsert == "0" ? handleFormSubmit : handleBulkFormSubmit
          }
        />
      </div>
    </Modal>
  );
};

export default List;
