import React, { useEffect, useState } from "react";
import { CrossLogo } from "../../../assets/images/svg";
import Button from "../../../components/Button";
import "./addActivityModal.scss";
import Select from "react-select";
import {
  getSchoolDropdown,
  getActivitiesDropdown,
  getGradeSectionDropDown,
  getGradeDropdown,
  getSchoolStaffDropdown,
  addActivity,
} from "./addActivityModalService";
import FormInput from "../../../components/Form/FormInput";

const AddActivityModal = ({
  displayModal,
  setShowModal,
  callApi,
  setCallApi,
}) => {
  // Function to close modal
  const handleClose = () => {
    setShowModal(false);
    setCallApi(false);
  };
  const [schoolDropdown, setSchoolDropdown] = useState([]);
  const [staffDropdown, setStaffDropdown] = useState([]);
  const [activityDropdown, setActivityDropdown] = useState([]);
  const [gradeDropDown, setGradeDropDown] = useState([]);
  const [sectionDropDown, setSectionDropDown] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedGradeSection, setSelectedGradeSection] = useState(null);
  const [selectedStaffIds, setSelectedStaffIds] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const token = localStorage.getItem("token");

  const handleSchoolChange = (e) => {
    setSectionDropDown([]);
    setSelectedSchool(e.target.value);
  };

  const handleActivityChange = (e) => {
    setSelectedActivity(e.target.value);
  };

  const handleGradeChange = async (e) => {
    console.log("called grade changes");
    setSelectedGrade(e.target.value);
    const response = await getGradeSectionDropDown(
      token,
      selectedSchool,
      e.target.value
    );
    setSectionDropDown(response);
  };

  const handleSectionChange = async (e) => {
    setSelectedGradeSection(e.target.value);
  };

  const handleStaffChange = (selectedOptions) => {
    // Map selected options to an array of staff IDs
    const selectedIds = selectedOptions.map((option) => option.value);
    console.log(selectedIds);
    setSelectedStaffIds(selectedIds);
  };

  const handleAddActivity = async () => {
    const data = await addActivity(
      token,
      selectedSchool,
      selectedActivity,
      selectedGrade,
      selectedGradeSection,
      selectedStaffIds,
      startTime,
      endTime
    );
    alert(data.message);
    handleClose(false);
  };

  useEffect(() => {
    const fetchSchoolDropdown = async () => {
      try {
        let data = await getSchoolDropdown();
        setSchoolDropdown(data);
      } catch (error) {
        console.error("Error fetching school dropdown:", error);
      }
    };

    fetchSchoolDropdown();
  }, []);

  useEffect(() => {
    const fetchGradeAndActivity = async () => {
      try {
        let gradeDropdownData = await getGradeDropdown(token, selectedSchool);
        let activityDropdownData = await getActivitiesDropdown(
          token,
          selectedSchool
        );
        let staffDropdownData = await getSchoolStaffDropdown(
          token,
          selectedSchool
        );

        setActivityDropdown(activityDropdownData);
        setGradeDropDown(gradeDropdownData);
        setStaffDropdown(staffDropdownData);
      } catch (error) {
        console.error("Error fetching school dropdown:", error);
      }
    };

    fetchGradeAndActivity();
  }, [selectedSchool]);

  const addActivityFormConfig = [
    {
      id: 1,
      name: "school",
      type: "select",
      placeholder: "School",
      options: schoolDropdown,
      required: true,
      onChange: handleSchoolChange,
      errorMessage: "Please select a school",
    },
    {
      id: 2,
      placeholder: "School Activity",
      type: "select",
      options: activityDropdown,
      required: true,
      onChange: handleActivityChange,
      errorMessage: "Please select a school activity",
      disabled: activityDropdown.length === 0,
    },
    {
      id: 3,
      placeholder: "School Grade",
      type: "select",
      options: gradeDropDown,
      required: true,
      onChange: handleGradeChange,
      errorMessage: "Please select a school grade",
      disabled: gradeDropDown.length === 0,
    },
    {
      id: 4,
      placeholder: "Grade Section",
      type: "select",
      options: sectionDropDown,
      required: true,
      onChange: handleSectionChange,
      errorMessage: "Please select a Grade section",
      disabled: sectionDropDown.length === 0,
    },
    {
      id: 5,
      placeholder: "Activity Staff",
      type: "select",
      options: staffDropdown,
      required: true,
      multiple: true,
      defaultValue: [staffDropdown[0], staffDropdown[1]],
      onChange: handleStaffChange,
      errorMessage: "Please select a school staff",
      disabled: staffDropdown.length === 0,
    },
    {
      id: "startTime",
      name: "startTime",
      type: "datetime-local",
      required: true,
      onChange: (e) => setStartTime(e.target.value),
      label: "Start Time:",
      errorMessage: "Please enter start time",
    },
    {
      id: "endTime",
      name: "endTime",
      type: "datetime-local",
      required: true,
      onChange: (e) => setEndTime(e.target.value),
      label: "End Time:",
      errorMessage: "Please enter end time",
    },
  ];

  return (
    <div>
      <div
        className={
          displayModal ? "modal show fade d-block" : "modal fade d-block"
        }
        style={{ backgroundColor: "rgba(0,0,0,.5)" }}
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Activity</h5>
              {/* Use arrow function to pass handleClose without invoking it immediately */}
              <div className="close-modal" onClick={handleClose}>
                <CrossLogo />
              </div>
            </div>
            <div className="modal-body reject-modal-body">
              <form onSubmit={handleAddActivity}>
                {addActivityFormConfig.map((item, index) => (
                  <FormInput key={index} {...item} />
                ))}
                <div className="modal-footer px-0">
                  <Button
                    label="Add"
                    type="submit"
                    backgroundColor={"#f6921e"}
                    className="w-100"
                    textColor="white"
                  />
                </div>
              </form>
              {/* <select
                className="form-select mb-2"
                aria-label="Default select example"
                onChange={handleSchoolChange}
              >
                <option selected>School</option>
                {schoolDropdown.map((item, index) => (
                  <option key={index} value={item?.value}>
                    {item?.label}
                  </option>
                ))}
              </select>
              <select
                className="form-select mb-2"
                aria-label="Default select example"
                onChange={handleActivityChange}
              >
                <option selected>School Activity</option>
                {activityDropdown.map((item, index) => (
                  <option key={index} value={item?.value}>
                    {item?.label}
                  </option>
                ))}
              </select>
              <select
                className="form-select mb-2"
                aria-label="Default select example"
                onChange={handleGradeChange}
              >
                <option selected>School Grade</option>
                {gradeDropDown.map((item, index) => (
                  <option key={index} value={item?.value}>
                    {item?.label}
                  </option>
                ))}
              </select>
              <select
                className="form-select mb-2"
                aria-label="Default select example"
                onChange={handleSectionChange}
              >
                <option selected>Grade Section</option>
                {sectionDropDown.map((item, index) => (
                  <option key={index} value={item?.value}>
                    {item?.label}
                  </option>
                ))}
              </select>
              <Select
                defaultValue={[staffDropdown[0], staffDropdown[1]]}
                isMulti
                name="colors"
                placeholder="Activity Staff"
                options={staffDropdown}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleStaffChange}
              />

              <label for="startTime">Start Time:</label>
              <input
                className="form-control"
                type="datetime-local"
                id="startTime"
                name="startTime"
                onChange={(e) => {
                  setStartTime(e.target.value);
                }}
              />
              <label for="endTime">End Time:</label>
              <input
                className="form-control mb-3"
                type="datetime-local"
                id="endTime"
                name="endTime"
                onChange={(e) => {
                  setEndTime(e.target.value);
                }}
              /> */}
            </div>
            {/* <div className="modal-footer"> */}
            {/* Close modal on Cancel click */}
            {/* <Button label="Close" backgroundColor="white" textColor="#EA983E" onClick={handleClose}/> */}
            {/* <Button
                label="Add"
                backgroundColor="#EA983E"
                textColor="white"
                type="submit"
                onClick={handleAddActivity}
              /> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddActivityModal;
