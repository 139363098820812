import { useDispatch, useSelector } from "react-redux";
import { DeleteLogo, EditLogo } from "../../../../../assets/images/svg";
import Card from "../../../../../components/Card";
import Modal from "../../../../../components/Modal/Modal";
import Table from "../../../../../components/Table/Table";
import {
  closeModal,
  openModal,
} from "../../../../../components/Modal/ModalSlice";
import "./index.scss";
import Button from "../../../../../components/Button";
import { useEffect, useState } from "react";
import { gradesData } from "./grades";
import { getGradeDropdown } from "../../../../master/activity/activityMasterService";
import DynamicFormRenderer from "../../../../../components/Form/DynamicFormRenderer";
import { useNavigate, useParams } from "react-router-dom";
import {
  createSchoolGradeAPI,
  getSchoolGradeAPI,
  schoolMasterDetails,
  updateSchoolGradeAPI,
} from "../../schoolMasterService";
import {
  setEditGradeId,
  setIsEditEntry,
  setIsNewEntry,
} from "../../schoolMasterSlice";

const Grade = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [gradesData, setGradesData] = useState([]);
  const [curriculum_id, setCurriculum_id] = useState();
  const [callApi, setCallApi] = useState(true);

  const token = localStorage.getItem("token");

  const schoolGradeStatusList = ["ALL", "Active", "Inactive"];

  useEffect(() => {
    const fetchCurriculumId = async (school_id) => {
      const data = await schoolMasterDetails(token, school_id);
      setCurriculum_id(data[0].curriculum_id);
    };

    const fetchSchoolGrades = async (school_id) => {
      const data = await getSchoolGradeAPI(school_id);
      const schoolGradesData = data.map((i) => {
        return {
          id: i.id,
          name: i.name,
          grade: i.grade_name,
          description: i.description,
          status: i.status,
        };
      });

      setGradesData(schoolGradesData);
    };

    fetchCurriculumId(id);
    fetchSchoolGrades(id);
    console.log(curriculum_id);
  }, [id, callApi]);

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width: "106px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <span
          className="text-truncate"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title={row.name}
        >
          {row.name}
        </span>
      ),
      width: "348px",
    },
    {
      name: "Grade",
      selector: (row) => row.grade,
      sortable: true,
      width: "348px",
    },
    {
      name: "Description",
      selector: (row) => row.description,
      cell: (row) => (
        <span
          className="text-truncate"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title={row.description}
        >
          {row.description}
        </span>
      ),
      width: "348px",
    },
    {
      name: "",
      button: true,
      cell: (row) => (
        <button
          className="btn"
          onClick={() => {
            // dispatch(setSchoolEditable, true);
            // navigate(`${row.id}`);
            dispatch(setIsEditEntry(true));
            dispatch(setIsNewEntry(false));
            dispatch(setEditGradeId(row.id));
            dispatch(openModal("gradeModal"));
          }}
        >
          <EditLogo />
        </button>
      ),
      width: "auto",
    },
    // {
    //   name: "",
    //   button: true,
    //   cell: (row) => (
    //     <button
    //       className="btn"
    //       onClick={() => {
    //         // dispatch(setSchoolEditable, true);
    //         // navigate(`${row.id}`);
    //       }}
    //     >
    //       <DeleteLogo />
    //     </button>
    //   ),
    //   width: "auto",
    // },
  ];

  return (
    <div className="school__master__grade--page">
      <Card>
        <div className="school__master__grade--list p-3 card-lg">
          <Table
            entity="school_grade_master"
            data={gradesData}
            columns={columns}
            statusList={schoolGradeStatusList}
            buttonLabel="+ Grade"
            onAddClick={() => {
              dispatch(setIsEditEntry(false));
              dispatch(setIsNewEntry(true));
              dispatch(setEditGradeId(null));
              dispatch(openModal("gradeModal"));
            }}
            clickableRow={false}
          />
        </div>

        <GradeModal
          curriculum_id={curriculum_id}
          gradesData={gradesData}
          callApi={callApi}
          setCallApi={setCallApi}
        />
      </Card>
    </div>
  );
};

const GradeModal = ({ curriculum_id, gradesData, callApi, setCallApi }) => {
  // const [tableData, setTableData] = useState(data);

  // const handleNameChange = (e, rowId) => {
  //   const { value } = e.target;
  //   setTableData((prevState) =>
  //     prevState.map((row) => (row.id === rowId ? { ...row, name: value } : row))
  //   );
  // };

  // const handleDescriptionChange = (e, rowId) => {
  //   const { value } = e.target;
  //   setTableData((prevState) =>
  //     prevState.map((row) =>
  //       row.id === rowId ? { ...row, description: value } : row
  //     )
  //   );
  // };

  // const columns = [
  //   {
  //     name: "Name",
  //     selector: (row) => row.name,
  //     sortable: true,
  //     cell: (row) => (
  //       <input
  //         type="text"
  //         className="form-control"
  //         id={row.id}
  //         name={row.id}
  //         value={row.name}
  //         onChange={(e) => handleNameChange(e, row.id)}
  //       />
  //     ),
  //     width: "30%",
  //   },
  //   {
  //     name: "Grade",
  //     selector: (row) => row.grade,
  //     sortable: true,
  //     width: "30%",
  //   },
  //   {
  //     name: "Description",
  //     selector: (row) => row.description,
  //     cell: (row) => (
  //       <input
  //         type="text"
  //         className="form-control"
  //         id={row.id}
  //         name={row.id}
  //         value={row.description}
  //         onChange={(e) => handleDescriptionChange(e, row.id)}
  //       />
  //     ),
  //     width: "30%",
  //   },
  // ];

  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [gradesDropdownOptions, setGradesDropdownOptions] = useState([]);
  const isEditEntry = useSelector((state) => state.schoolMaster.isEditEntry);
  let editGradeId = useSelector((state) => state.schoolMaster.editGradeId);
  let currentGradeData;
  if (isEditEntry) {
    currentGradeData = gradesData.find((data) => data.id === editGradeId);
    console.log(currentGradeData);
  }

  useEffect(() => {
    const fetchGrades = async (curriculum_id) => {
      const data = await getGradeDropdown(curriculum_id);
      setGradesDropdownOptions(data);
    };

    fetchGrades(curriculum_id);
  }, [curriculum_id]);

  const formConfig = [
    {
      name: "name",
      label: "Name",
      type: "text",
      value: isEditEntry ? currentGradeData?.name : "",
      required: true,
    },
    {
      name: "gradeMaster",
      label: "Grade",
      type: "select",
      options: gradesDropdownOptions,
      value: isEditEntry
        ? gradesDropdownOptions.find(
            (data) => data.label === currentGradeData?.grade
          )?.value
        : "",
      required: true,
    },
    {
      name: "description",
      label: "Description",
      type: "textarea",
      value: isEditEntry ? currentGradeData?.description : "",
    },
    {
      name: "status",
      label: "Status",
      type: "select",
      options: [
        { label: "Active", value: "active" },
        { label: "Inactive", value: "inactive" },
      ],
      value: isEditEntry ? currentGradeData?.status : "",
      required: true,
    },
  ];

  const handleSubmit = async (data) => {
    let d = {
      name: data.name,
      description: data.description,
      school_id: Number(id),
      status: data.status,
      grade_id: data.gradeMaster,
    };

    if (isEditEntry) {
      d = {
        ...d,
        id: editGradeId,
      };
    }

    try {
      const response = isEditEntry
        ? await updateSchoolGradeAPI(d)
        : await createSchoolGradeAPI(d);
      if (response.status) {
        alert(response.message);
        dispatch(setIsEditEntry(false));
        dispatch(setIsNewEntry(false));
        dispatch(setEditGradeId(null));
        dispatch(closeModal());
        callApi ? setCallApi(false) : setCallApi(true);
      } else {
        throw Error(response.message);
      }
    } catch (error) {
      alert(error.message);
      dispatch(setIsEditEntry(false));
      dispatch(setIsNewEntry(false));
      dispatch(setEditGradeId(null));
      dispatch(closeModal());
    }
  };

  return (
    <Modal
      title={isEditEntry ? "Edit Grade" : "Add Grade"}
      onCloseClick={() => {
        dispatch(setIsEditEntry(false));
        dispatch(setIsNewEntry(false));
        dispatch(setEditGradeId(null));
      }}
    >
      <div className="d-flex h-100 flex-column">
        <DynamicFormRenderer
          formConfig={formConfig}
          handleSubmit={handleSubmit}
          onCancel={() => {
            dispatch(setIsEditEntry(false));
            dispatch(setIsNewEntry(false));
            dispatch(setEditGradeId(null));
            dispatch(closeModal());
          }}
        />
      </div>
    </Modal>
  );
};

export default Grade;
