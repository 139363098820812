import React from 'react';
import "./index.scss";
import { ChevronRight } from '../../assets/images/svg';
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import carrotImage from "../../assets/images/media/Vector.png"

const SubHeader = () => {
  const location = useLocation();

  const {breadCrumbHeader} = useSelector((state) => state.breadCrumb)
  console.log(breadCrumbHeader)

  // Split the current path into segments
  const pathSegments = location.pathname.split('/').filter(Boolean);

  // Function to generate the breadcrumb label from the path segment
  const generateBreadcrumbLabel = (segment) => {
    console.log(typeof segment)

    // Customize mapping for specific segments if needed
    const mappings = {
      'activity': 'Activity',
      'home': 'Home',
      '':'Activity',
      'curriculum-master': 'Curriculum',
      'activity-master': 'Activity'
      // Add more mappings as needed
    };

    return segment ? (mappings[segment] || segment.charAt(0).toUpperCase() + segment.slice(1)) : "Activity";
  };

  const getHeaderSegment = () => {
    const lastSegment = pathSegments[pathSegments.length - 1];
    const secondLastSegment = pathSegments[pathSegments.length - 2];

    // If the last segment is a number, use the second last; otherwise, use the last.
    return isNaN(lastSegment) ? lastSegment : breadCrumbHeader || 'Home';
  };

  // Create an array of breadcrumb links based on the path
  const breadcrumbs = pathSegments.map((segment, index) => {
    // Create the link path for each breadcrumb
    const linkPath = `/${pathSegments.slice(0, index + 1).join('/')}`;
    console.log("length ", pathSegments.length, index)

    return (
      <React.Fragment key={linkPath}>
        <Link to={linkPath} className="breadcrumb">
          {generateBreadcrumbLabel(segment)}
        </Link>
        {index < pathSegments.length - 1 && <img src={carrotImage}/>}
      </React.Fragment>
    );
  });

  return (
    <div className="subheader">  
      <h2 className="activity-section">{generateBreadcrumbLabel(getHeaderSegment())}</h2>
      <div className="breadcrumb-section">
        <Link to="/" className="breadcrumb">Home</Link>
        <img src={carrotImage}/>
        {breadcrumbs}
      </div>
    </div>
  );
};

export default SubHeader;
