import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  courses: [],
  isCourseEditable: false,
  isNewEntry: false,
  isEditEntry: false,
};

const courseMasterSlice = createSlice({
  name: "courseMaster",
  initialState,
  reducers: {
    setCoursesMaster: (state, action) => {
      state.courses = action.payload;
    },
    setIsCourseEditable: (state, action) => {
      state.isCourseEditable = action.payload;
    },
    setIsNewEntry: (state, action) => {
      state.isNewEntry = action.payload;
    },
    setIsEditEntry: (state, action) => {
      state.isEditEntry = action.payload;
    },
  },
});

export const {
  setCoursesMaster,
  setIsCourseEditable,
  setIsNewEntry,
  setIsEditEntry,
} = courseMasterSlice.actions;

export default courseMasterSlice.reducer;
