import { createSlice } from "@reduxjs/toolkit";

const staffMasterSlice = createSlice({
  name: "staffMaster",
  initialState: {
    staffs: [],
    selectedStaff: null,
    isStaffEditable: false,
    isNewEntry: false,
    isEditEntry: false,
  },
  reducers: {
    setStaffs: (state, action) => {
      state.staffs = action.payload;
    },
    setIsStaffEditable: (state, action) => {
      state.isStaffEditable = action.payload;
    },
    setIsNewEntry: (state, action) => {
      state.isNewEntry = action.payload;
    },
    setIsEditEntry: (state, action) => {
      state.isEditEntry = action.payload;
    },
    setSelectedStaff: (state, action) => {
      state.selectedStaff = action.payload;
    },
  },
});

export const {
  setStaffs,
  setIsStaffEditable,
  setIsNewEntry,
  setIsEditEntry,
  setSelectedStaff,
} = staffMasterSlice.actions;
export default staffMasterSlice.reducer;
