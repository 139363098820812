import React, { useEffect, useState } from "react";
import {
  addGrade,
  fetchSingleGrade,
  updateGrade,
} from "../curriculumDetailsService";
import { CrossLogo } from "../../../../../assets/images/svg";
import Button from "../../../../../components/Button";
import DynamicFormRenderer from "../../../../../components/Form/DynamicFormRenderer";

const AddGradeModal = ({
  setShowModal,
  showAlert,
  setShowAlert,
  alertMessage,
  setAlertMesage,
  setApiCall,
  apiCall,
  id,
  setIsEditable,
  isEditable,
  gradeId,
}) => {
  const statusOptions = ["active", "inactive"];
  const [currentGrade, setCurrentGrade] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentGrade((prev) => ({
      ...prev,
      [name]: value, // Dynamically update the correct field
    }));
  };

  useEffect(() => {
    const fetchGrade = async () => {
      const data = await fetchSingleGrade(gradeId);
      setCurrentGrade(data);
    };

    if (isEditable) {
      fetchGrade();
    }
  }, [isEditable]);

  const formConfig = [
    {
      name: "id",
      label: "Id",
      type: "text",
      placeholder: "Enter Id",
      value: isEditable ? currentGrade.id : "",
      disabled: true,
    },
    {
      name: "name",
      label: "Name",
      type: "text",
      placeholder: "Enter Name",
      value: currentGrade.name,
      required: true,
    },
    {
      name: "status",
      label: "Status",
      type: "select",
      placeholder: "Select Status",
      options: [
        { value: "active", label: "Active" },
        { value: "inactive", label: "Inactive" },
      ],
      required: true,
      value: isEditable ? currentGrade.status : "",
    },
    {
      name: "description",
      label: "Description",
      type: "textarea",
      placeholder: "Enter Description",
      required: true,
      value: isEditable ? currentGrade.description : "",
    },
  ];

  const handleFormSubmit = async (data) => {
    let grades = data;
    grades.curriculum_id = id;
    console.log(
      "Received grades >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",
      grades
    );
    if (isEditable) {
      await updateGrade(grades);
      setAlertMesage("Updated Grade");
    } else {
      await addGrade(grades);
      setAlertMesage("Added New Grade");
    }
    setShowAlert(true);
    setIsEditable(false);
    setShowModal(false);
    if (apiCall) setApiCall(false);
    else setApiCall(true);
  };

  const onHandleCloseClick = () => {
    setShowModal(false);
    setIsEditable(false);
  };

  return (
    <div>
      <div
        className="modal show fade d-block"
        role="dialog"
        style={{ backgroundColor: "rgba(0,0,0,.5)" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Grade</h5>
              <div className="close-modal" onClick={onHandleCloseClick}>
                <CrossLogo />
              </div>
            </div>
            <div className="modal-body reject-modal-body">
              {/* <div className="add-curriculum-form">
            <div className="row mb-2 p-2">
                <div className="col-6">
                <label htmlFor="curriculumId">Id</label>
                <input
                    type="text"
                    className="form-control"
                    id="curriculumId"
                    name="id" // Add name for controlled component
                    placeholder="Enter Id"
                    onChange={undefined}
                readOnly/>
                </div>
                <div className="col-6">
                <label htmlFor="curriculumName">Name</label>
                <input
                    type="text"
                    className="form-control"
                    id="curriculumName"
                    name="name" // Add name for controlled component
                    placeholder="Enter Name"
                    onChange={handleInputChange}
                    value={currentGrade?.name}
                />
                </div>
            </div>
            <div className="row mb-2 p-2">
                <div className="col-6">
                <label htmlFor="curriculumStatus">Status</label>
                <select
                    className="form-control"
                    id="curriculumStatus"
                    name="status" // Add name for controlled component
                    onChange={handleInputChange}
                    value={currentGrade?.status}
                >
                    <option value="">Select Status</option> 
                    {statusOptions.map((status) => (
                    <option key={status} value={status}>
                        {status}
                    </option>
                    ))}
                </select>
                </div>
                <div className="col-6">
                <label htmlFor="curriculumDescription">Description</label>
                <textarea
                    type="text" // Changed type to "text" for description
                    className="form-control"
                    id="curriculumDescription"
                    name="description" // Add name for controlled component
                    placeholder="Enter Description"
                    rows="3"
                    onChange={handleInputChange}
                    value={currentGrade?.description}
                ></textarea>
                </div>
            </div>
            <div className="row mb-2 p-2">
                <div className="col-6">
                <label htmlFor="sequence">Sequence</label>
                <input
                    type="text"
                    className="form-control"
                    id="curriculumName"
                    name="sequence" // Add name for controlled component
                    placeholder="Enter Sequence"
                    onChange={handleInputChange}
                />
                </div>
            </div>
        </div>
              </div>
              <div className="modal-footer">
                 <Button label="Save" onClick={handleFormSubmit}  backgroundColor="#EA983E" textColor="white"/>
              </div> */}
              <DynamicFormRenderer
                formConfig={formConfig}
                handleSubmit={handleFormSubmit}
                hasCancelBtn={false}
                submitBtnText="Save"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddGradeModal;
