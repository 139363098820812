import { createSlice } from "@reduxjs/toolkit";

const initialState= {
    breadCrumbHeader : ""
}

const breadCrumbSlice = createSlice({
    name : "breadCrumb",
    initialState,
    reducers : {
        setBreadCrumbHeader : (state, action) => {
            state.breadCrumbHeader = action.payload
        }
    }
})

export const {setBreadCrumbHeader} = breadCrumbSlice.actions;

export default breadCrumbSlice.reducer