import React, { useEffect, useState } from "react";
import "./courseDetails.scss";
import Button from "../../../components/Button";
import { useParams } from "react-router-dom";
import {
  assessmentDetails,
  assessmentDetailsV2,
  assessmentStatusUpdate,
  vivaSchedule,
} from "./courseDetailsService";
import { useDispatch, useSelector } from "react-redux";
import {
  setAssessments,
  setAssessmentsMultipleFileTypes,
} from "./courseDetailsSlice";
import {
  AttachmentLogo,
  CalenderLogo,
  TimeLogo,
  TimeZoneLogo,
  CrossLogo,
} from "../../../assets/images/svg";
import successGif from "../../../assets/images/media/success.gif";
import SYLListing from "./SYLListing/SYLListing";
import AlertWithIcon from "../../../components/Alerts/AlertWithIcon";

const CourseDetails = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const token = localStorage.getItem("token");
  const [activeTab, setActiveTab] = useState("Assesment");
  const [status, setStatus] = useState("approved");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [mode, setMode] = useState("");
  const [link, setLink] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [remark, setRemark] = useState("");
  const [assessmentStage, setAssessmentStage] = useState("video");
  const [apiCall, SetApiCall] = useState(new Date());
  const [errors, setErrors] = useState({
    endDateError: "",
    linkError: "",
  });
  const [assessentSubmissionId, setAssessmentSubmissionId] = useState(null);

  let assessmentlist;

  useEffect(() => {
    const fetchCourseDetails = async () => {
      const data = await assessmentDetails(token, id);
      dispatch(setAssessments(data));
    };

    const fetchCourseDetailsMultipleFileType = async () => {
      const data = await assessmentDetailsV2(token, id);
      dispatch(setAssessmentsMultipleFileTypes(data.data));
    };

    fetchCourseDetails();
    fetchCourseDetailsMultipleFileType();
  }, [dispatch, token, status, apiCall]);

  assessmentlist = useSelector(
    (state) => state.assessmentDetail.assessmentDetails
  );

  const scheduleViva = async (course_assessment_submission_id) => {
    const data = await vivaSchedule(
      token,
      course_assessment_submission_id,
      startDate,
      endDate,
      mode,
      link
    );
    alert(data.message);
    SetApiCall(new Date());
  };

  const handleClick = async (
    assesmentStatus,
    type,
    body,
    message = "",
    assessmentId = assessentSubmissionId
  ) => {
    if (type == "video_upload") {
      const data = await assessmentStatusUpdate(
        token,
        assessmentId,
        assesmentStatus,
        id,
        message
      );
      alert(data.message);
      setStatus("approved");
      setShowModal(false);
      SetApiCall(new Date());
    }
    if (type === "viva") {
      const data = await assessmentStatusUpdate(
        token,
        assessmentId,
        assesmentStatus,
        id,
        message
      );
      alert(data.message);
      setShowModal(false);
      SetApiCall(new Date());
    }
  };

  const handleReject = (type, assessmentSubmissionId) => {
    setAssessmentSubmissionId(assessmentSubmissionId);
    setAssessmentStage(type);
    setShowModal(true);
  };

  const validateEndDate = (start, end) => {
    if (new Date(end) < new Date(start)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        endDateError: "End date must be later than or equal to the start date.",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, endDateError: "" }));
    }
  };

  const validateLink = (link) => {
    if (mode === "virtual" && (!link || !/^(https?:\/\/[^\s]+)$/.test(link))) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        linkError: "Please provide a valid link for virtual mode.",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, linkError: "" }));
    }
  };

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    setStartDate(newStartDate);
    if (endDate) {
      validateEndDate(newStartDate, endDate);
    }
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    setEndDate(newEndDate);
    validateEndDate(startDate, newEndDate);
  };

  const handleModeChange = (e) => {
    const selectedMode = e.target.value;
    setMode(selectedMode);
    if (selectedMode === "virtual") {
      validateLink(link); // Validate link when switching to virtual
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, linkError: "" }));
    }
  };

  const handleLinkChange = (e) => {
    const newLink = e.target.value;
    setLink(newLink);
    validateLink(newLink);
  };

  const isFormValid =
    !errors.endDateError &&
    !errors.linkError &&
    startDate &&
    endDate &&
    (mode === "physical" || link);

  return (
    <div className="course-details-page">
      <div className="tabs page-tab">
        <div
          className={`tab ${activeTab === "details" ? "tab-active" : ""}`}
          onClick={() => setActiveTab("details")}
        >
          Details
        </div>
        <div
          className={`tab ${activeTab === "Assesment" ? "tab-active" : ""}`}
          onClick={() => setActiveTab("Assesment")}
        >
          Assesment
        </div>
      </div>
      {activeTab === "details" && (
        <div className="tab-content details-content">Details Content</div>
      )}
      {activeTab === "Assesment" && (
        <div className="tab-content details-content">
          <div className="assessment-content">
            <SYLListing />
          </div>
          {console.log("assessmentlist: ", assessmentlist)}
          {assessmentlist.map((val) =>
            val.assessment_type == "video_upload" ? null : (
              <div className="assessment-content viva p-3">
                <div className="title">Viva</div>
                {
                  // val?.details?.id &&
                  val?.details?.id || val?.status == "ready" ? (
                    <div>
                      {val?.status == "ready" && (
                        <div>
                          <div className="row">
                            <div className="col-6">
                              <div className="viva-element">
                                <label>Select start date</label>
                                <input
                                  type="datetime-local"
                                  value={startDate}
                                  onChange={handleStartDateChange}
                                  className="form-control"
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="viva-element">
                                <label>Select end date</label>
                                <input
                                  type="datetime-local"
                                  value={endDate}
                                  onChange={handleEndDateChange}
                                  className="form-control"
                                  min={startDate}
                                  required
                                />
                                {errors.endDateError && (
                                  <small className="text-danger">
                                    {errors.endDateError}
                                  </small>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="location-container viva-element">
                            Location
                            <div className="viva-assessment-form">
                              <div>
                                <input
                                  type="radio"
                                  id="physical"
                                  name="mode"
                                  value="physical"
                                  checked={mode === "physical"}
                                  onChange={handleModeChange}
                                  // onChange={(e) => setMode(e.target.value)}
                                />
                                <label for="physical">Physical</label>
                              </div>
                              <div>
                                <input
                                  type="radio"
                                  id="virtual"
                                  name="mode"
                                  value="virtual"
                                  checked={mode === "virtual"}
                                  onChange={handleModeChange}
                                  // onChange={(e) => setMode(e.target.value)}
                                />
                                <label for="virtual">Virtual</label>
                              </div>
                              {mode === "virtual" && (
                                <div className="input-link-wrapper">
                                  <input
                                    type="text"
                                    className="input-link form-control"
                                    name="link"
                                    placeholder="Link"
                                    value={link}
                                    onChange={handleLinkChange}
                                    required={mode === "virtual"}
                                  />
                                  {errors.linkError && (
                                    <small className="text-danger">
                                      {errors.linkError}
                                    </small>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="vid-approve-buttons">
                            <Button
                              label="Schedule"
                              onClick={() => scheduleViva(val?.id)}
                              backgroundColor="#EA983E"
                              textColor="white"
                              disabled={!isFormValid}
                            />
                          </div>
                        </div>
                      )}
                      {val?.status === "scheduled" && (
                        <div className="scheduled-viva-screen">
                          <div className="scheduled-viva">
                            <div className="viva-schedule-gif">
                              <img
                                src={successGif}
                                alt="viva scheduled successfullly"
                              />
                            </div>
                            {/* <div className="viva-schedule-title">
                            Viva Scheduled!
                          </div> */}
                            <AlertWithIcon
                              type="info"
                              message="Viva Scheduled!"
                              icon={"info"}
                              className="my-3 mx-auto"
                            />
                            <div className="description">
                              Click on approve or reject viva once completed to
                              move member to the next page
                            </div>
                            <div className="scheduled-viva-details">
                              <div className="schedule-viva-card">
                                <div className="viva-card-items">
                                  <TimeLogo /> {val?.details?.comment?.duration}
                                </div>
                                <div className="viva-card-items">
                                  <CalenderLogo />{" "}
                                  {val?.details?.comment?.start_time}
                                </div>
                                <div className="viva-card-items">
                                  <TimeZoneLogo /> Indian Standard Time
                                </div>
                                <div className="viva-card-items">
                                  <AttachmentLogo /> Viva link :{" "}
                                  <span className="meeting-link">
                                    {val?.details?.comment?.url}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="vid-approve-buttons">
                            <Button
                              label="Approve"
                              onClick={() => {
                                setAssessmentSubmissionId(val?.id);
                                handleClick("approve", "viva", {}, "", val?.id);
                              }}
                              backgroundColor="#EA983E"
                              textColor="white"
                              className="vid-approve-buttons__approve"
                            />
                            <Button
                              label="Reject"
                              onClick={() => handleReject("viva", val?.id)}
                              backgroundColor="white"
                              textColor="#EA983E"
                            />
                          </div>
                        </div>
                      )}
                      {val?.status == "completed" && (
                        <AlertWithIcon
                          type="success"
                          message="Viva Completed!"
                          icon={"success"}
                          className="my-3"
                        />
                      )}
                      {val?.status == "rejected" && (
                        <AlertWithIcon
                          type="danger"
                          message="Viva Rejected! Please wait for ready state"
                          icon={"danger"}
                          className="my-3"
                        />
                      )}
                    </div>
                  ) : (
                    <h1>OOPS!! No Submissions yet</h1>
                  )
                }
              </div>
            )
          )}
        </div>
      )}
      {showModal && (
        <div
          className="modal show fade d-block"
          role="dialog"
          style={{ backgroundColor: "rgba(0,0,0,.5)" }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Reject</h5>
                <div
                  className="close-modal"
                  onClick={() => setShowModal(false)}
                >
                  <CrossLogo />
                </div>
              </div>
              <div className="modal-body reject-modal-body">
                <h4>Are you sure you want to Reject?</h4>
                <label className="mb-2" htmlFor="remark">
                  Add a remark for rejection*
                </label>
                <textarea
                  type="textarea"
                  placeholder="Remark"
                  value={remark}
                  name="remark"
                  id="remark"
                  onChange={(e) => setRemark(e.target.value)}
                  rows="5"
                  cols="20"
                ></textarea>
              </div>
              <div className="modal-footer">
                <Button
                  label="Cancel"
                  onClick={() => setShowModal(false)}
                  backgroundColor="white"
                  textColor="#EA983E"
                />
                {assessmentStage == "video" ? (
                  <Button
                    label="Reject"
                    onClick={() =>
                      handleClick("reject", "video_upload", {}, remark)
                    }
                    backgroundColor="#EA983E"
                    textColor="white"
                  />
                ) : (
                  <Button
                    label="Reject"
                    onClick={() => handleClick("reject", "viva", {}, remark)}
                    backgroundColor="#EA983E"
                    textColor="white"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseDetails;
