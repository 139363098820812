import React, { useState } from "react";
import CurriculumDetailsTab from "./detail/Detail";
import GradeList from "./grade/GradeList";
import "./curriculumDetails.scss"
const CurriculumDetails = () => {
  const [activeTab, setActiveTab] = useState("details");

  return (
    <div className="curriculum-details-page">
      <div className="tabs page-tab">
        <div
          className={`tab ${activeTab === "details" ? "tab-active" : ""}`}
          onClick={() => setActiveTab("details")}
        >
          Details
        </div>
        <div
          className={`tab ${
            activeTab === "grade" ? "tab-active" : ""
          }`}
          onClick={() => setActiveTab("grade")}
        >
          Grade
        </div>
      </div>
      {activeTab === "details" ? <CurriculumDetailsTab/> : < GradeList/>}
    </div>
  );
};

export default CurriculumDetails;
