// src/features/login/loginSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activities: []
};

const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    setActivity: (state, action) => {
      state.activities = action.payload;
    }
  }
});

export const { setActivity } = activitySlice.actions;

export default activitySlice.reducer;
