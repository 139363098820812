import axios from "axios";
import { DEV_BASE_URL } from "../../../utils/constants/routes";
import { curriculumDetails } from "../curriculum/curricumListService";
import { fetchAllGrade } from "../curriculum/curriculumDetails/curriculumDetailsService";

export const activityMasterDetails = async (token) => {
  const response = await axios.get(`${DEV_BASE_URL}/api/v1/getActivity`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data.data;
};

export const fetchSingleActivity = async (id) => {
  const response = await axios.get(`${DEV_BASE_URL}/api/v1/getActivity`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    params: {
      activity_id: id,
    },
  });

  let data = response.data.data[0];
  data.highlight_img = data.cover_picture_url
  data.highlight_vid = data.cover_video_url
  return data

};

export const getCurriculumDropdown = async () => {
  const response = await curriculumDetails();
  let dropdownData = [];
  response.forEach((element) => {
    dropdownData.push({
      value: element.id,
      label: element.name,
    });
  });

  return dropdownData;
};

export const getGradeDropdown = async (id) => {
  const response = await fetchAllGrade(id);
  let dropdownData = [];
  response.forEach((element) => {
    dropdownData.push({
      value: element.id,
      label: element.name,
    });
  });

  return dropdownData;
};

export const addActivity = async (currentActivityDetails) => {
  const formData = new FormData();
  formData.append("name", currentActivityDetails.name)
  formData.append("description", currentActivityDetails.description)
  formData.append("curriculum_id", currentActivityDetails.curriculum_id)
  formData.append("grade_id", currentActivityDetails.grade_id)
  formData.append("status", currentActivityDetails.status)
  formData.append("highlight_img", currentActivityDetails.highlight_img)
  formData.append("highlight_vid", currentActivityDetails.highlight_vid)
  formData.append("course_id", 2)

  const response = await axios.post(`${DEV_BASE_URL}/api/v1/createActivity`,
    formData,
    {
      headers : {
        'Authorization' : `Bearer ${localStorage.getItem('token')}`
      }
    }

  )

  return response.data
};

export const updateActivity = async (currentActivityDetails) => {
  const formData = new FormData();
  formData.append("name", currentActivityDetails.name)
  formData.append("id", currentActivityDetails.id)
  formData.append("description", currentActivityDetails.description)
  formData.append("curriculum_id", currentActivityDetails.curriculum_id)
  formData.append("grade_id", currentActivityDetails.grade_id)
  formData.append("status", currentActivityDetails.status)
  if(typeof currentActivityDetails.highlight_img != "string")
    formData.append("highlight_img", currentActivityDetails.highlight_img)
  if(typeof currentActivityDetails.highlight_vid != "string")
    formData.append("highlight_vid", currentActivityDetails.highlight_vid)
  formData.append("course_id", 2)

  const response = await axios.post(`${DEV_BASE_URL}/api/v1/updateActivity`,
    formData,
    {
      headers : {
        'Authorization' : `Bearer ${localStorage.getItem('token')}`
      }
    }

  )

  return response.data
};
