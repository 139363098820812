import { capitalizeString } from "./capitalizer";

export const renderTab = (tabName, index, activeTab, setActiveTab) => {
  return (
    <div
      key={index}
      className={`tab ${activeTab === tabName ? "tab-active" : ""}`}
      onClick={() => setActiveTab(tabName)}
    >
      {capitalizeString(tabName)}
    </div>
  );
};
